import _ from 'lodash';
import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

const mutation = graphql`
mutation SignInMutation($email: String, $password: String) {
  signInMutation(email: $email, password: $password) {
    ok
    error
    user {
      id
      firstname
      email
    }
  }
}
`;

export default (email, password, done) => {
  const variables = {
    email,
    password
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: () => {
      },
      optimisticUpdater: () => {
      },
      onCompleted: (response) => {
        const { ok, error } = response.signInMutation;
        done(ok, error);
      },
      onError: err => {
        console.error(err);
      }
    }
  );
};
