/**
 * @generated SignedSource<<83cad2af02c69109814e4d9ce032eedd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "categoryId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "productIds"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "categoryId",
        "variableName": "categoryId"
      },
      {
        "kind": "Variable",
        "name": "productIds",
        "variableName": "productIds"
      }
    ],
    "concreteType": "AddProductInStoreMutationResponse",
    "kind": "LinkedField",
    "name": "addProductInStoreMutation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CategoryStore",
        "kind": "LinkedField",
        "name": "categoryStore",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "products",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddProductInStoreMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddProductInStoreMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "ddf1ee7ad5c31d2612403c90549c9526",
    "id": null,
    "metadata": {},
    "name": "AddProductInStoreMutation",
    "operationKind": "mutation",
    "text": "mutation AddProductInStoreMutation(\n  $categoryId: ID\n  $productIds: [ID]\n) {\n  addProductInStoreMutation(categoryId: $categoryId, productIds: $productIds) {\n    ok\n    error\n    categoryStore {\n      id\n      products {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "8fd20ccc5a97c37c33c4232c7dd3bde5";

module.exports = node;
