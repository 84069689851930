const R = require('ramda');
import React, { useState } from 'react';

import qs from 'querystring';
import S from 'string';

import { withRouter } from 'react-router-dom';

import { 
  Row,
  Col,
  Input, 
  Button 
} from 'antd';

import ProductList from './components/ProductList';

const All = ({
  history
}) => {
  const [filter, setFilter] = useState(() => {
    const query = qs.parse(S(location.search).chompLeft('?').s);
    return R.propOr('', 'search', query);
  });

  const onChange = (e) => {
    setFilter(e.target.value);
    history.replace(`/products?search=${e.target.value}`);
  };
 
  return (
    <React.Fragment>
      <Row>
        <Col span={3}>
          <Button style={{ width: '98%' }} to="/products/new">Créer un produit</Button>
        </Col>
        <Col span={17}>
          <Input
            value = {filter}
            onChange={onChange}
            placeholder="rechercher un produit"
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 8 }}>
        <Col span={20}>
          <ProductList 
            showActions={true}
            filter={{ name: filter }} 
            count={25}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withRouter(All);
