import React from 'react';
import PropTypes from 'prop-types';
import VariationOptionCoreEdit from './VariationOptionCoreEdit';
import { Button } from '../uikit/UIKit';
import rs from 'randomstring';

import { assoc, is, lensIndex, remove, over } from 'ramda';

const VariationOptions = ({
  defaultOption,
  options,
  onSetDefaultOption,
  onUpdate
}) => {

  const $onUpdate = (options) => {
    if (is(Function, onUpdate)) return onUpdate(options);
  };

  const $onAddOption = (e) => {
    e.preventDefault();
    const option = {
      key: rs.generate({ length: 6 }), name: '',
      price: 0,
      shippingCost: 0,
      stock: 0
    };
    if (!is(Array, options)) {
      $onUpdate([option]);
    } else {
      $onUpdate([...options, option]);
    }
  };

  const $onRemoveOption = (index) => () => {
    $onUpdate(remove(index, 1, options));
  };

  const $onSetDefaultOption = (optionKey) => () => {
    if (is(Function, onSetDefaultOption)) onSetDefaultOption(optionKey);
  };

  const $onUpdateOption = (index) => (fieldname, value) => {
    $onUpdate(over(lensIndex(index), assoc(fieldname, value), options));
    //update(options, { $splice: [[index, 1, { ...options[index], [fieldname]: value }]] }));
  };

  return (
    <div>
      {options.map((option, index) => (
        <VariationOptionCoreEdit
          key={option.key}
          index={index}
          option={option}
          isDefaultOption={defaultOption === option.key}
          onUpdate={$onUpdateOption(index)}
          onSetDefault={$onSetDefaultOption(option.key)}
          onRemove={$onRemoveOption(index)}
        />
      ))}
      <Button
        size="small"
        onClick={$onAddOption}
      >
        Ajouter une option
      </Button>
    </div>
  );
};

VariationOptions.propTypes = {
  defaultOption: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
      price: PropTypes.number,
      shippingCost: PropTypes.number,
      stock: PropTypes.number
    })
  ),
  onUpdate: PropTypes.func,
  onSetDefaultOption: PropTypes.func
};

export default VariationOptions;