import Immutable from 'immutable';
import URL from 'url';
import fetch from 'isomorphic-fetch';

const Tokens = (() => {
  var vars = Immutable.fromJS({
    clientId: null,
    key: null,
    tokens: {},
    useToken: true
  });

  const buildUrl = (pathname, query) => {
    return URL.format({ pathname, query });
  };

  const setKey = (clientId, key) => {
    vars = vars.set('key', key).set('clientId', clientId);
  };

  var getAccessToken = () => {
    if (vars.get('useToken') === false) {
      return Promise.resolve('');
    }

    if (!vars.get('tokens').isEmpty()) return Promise.resolve(vars.getIn(['tokens', 'access_token']));

    return fetch(
      buildUrl('/u/token'),
      {
        method: 'POST',
        body: JSON.stringify({ grant_type: 'client_credentials' }),
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Basic ' + btoa(`${vars.get('clientId')}:${vars.get('key')}`),
          'Content-Type': 'application/json'
        }
      }
    ).then(response => {
      return response.json();
    }).then(tokens => {
      vars = vars.set('tokens', Immutable.fromJS(tokens));
      return vars.getIn(['tokens', 'access_token']);
    });
  };

  const setUseToken = (useToken) => {
    vars = vars.set('useToken', useToken);
  };

  return {
    getAccessToken,
    setKey,
    setUseToken
  };
})();

export default Tokens;