import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { graphql, createFragmentContainer } from 'react-relay';
import ShippingAddress from './addresses/ShippingAddress';
import BillingAddress from './addresses/BillingAddress';

const S_OrderAddresses = styled.div`
  margin: 32px 0px 24px 0px;
  width: 456px;
  .cart-line {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
  }
  .cart-extra-infos {
    margin-top: 8px;
    font-size: 10px;
    color: #333;
  }
`;

const OrderAddresses = ({ addresses }) => {
  return (
    <div>
      <ShippingAddress address={addresses.shipping} onCountryChange={(country) => this.props.onShippingCountryChange(country)}/>
      {!_.isNull(addresses.shipping) && <BillingAddress address={addresses.billing} billingSameAsShipping={addresses.billingSameAsShipping}/>}
    </div>
  );
};

export default createFragmentContainer(
  OrderAddresses, 
  {
    addresses: graphql`
      fragment OrderAddresses_addresses on Addresses {
        shipping {
          ...Address_address
        }
        billing {
          ...Address_address
        }
        billingSameAsShipping
      }
    `
  }
);