import './Layout.less';

import React from 'react';

import MenuLeft from '../menu/MenuLeft';

import {
  Layout,
} from 'antd';

const {
  Header, Footer, Sider, Content,
} = Layout;

const AdminLayout = ({
  children,
  userIsSignedIn
}) => {

  if (userIsSignedIn) return (
    <Layout className="admin-layout">
      <Sider
        style={{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0 }}
        width="300"
        className="layout-sider"
      >
        <MenuLeft />
      </Sider>
      <Layout>
        <Content className="layout-content">{children}</Content>
      </Layout>
    </Layout>
  )

  return (
    <Layout className="admin-layout">
      {children}
    </Layout >
  )
};

export default AdminLayout;