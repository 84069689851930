import Immutable from 'immutable';
import { handleActions } from 'redux-actions';
import rs from 'randomstring';

const emptyOption = Immutable.fromJS({
  name: '',
  price: 0,
  shippingCost: 0,
  stock: 0
});



const initialState = Immutable.fromJS({
  allFetching: false,
  all: [],
  imageUpload: {
    droppedImages: [],
    uploadProgress: 0
  },
  product: {
    name: '',
    description: '',
    shippingCost: 0,
    stock: 0,
    pictures: [],
    variation: {
      name: '',
      default: '',
      options: [emptyOption]
    }
  },
  newProduct: {
    name: '',
    description: '',
    shippingCost: 0,
    stock: 0,
    variation: {
      name: '',
      default: '',
      options: [emptyOption]
    }
  }
});

export const products = handleActions({
  PRODUCTS_FETCHING_SET: (state, action) => {
    return state.set('productsFetching', action.payload);
  },
  PRODUCTS_ALL_FETCHING_SET: (state, action) => {
    return state.set('allFetching', action.payload);
  },
  
  PRODUCTS_ALL_SET: (state, action) => {
    return state.set('all', Immutable.fromJS(action.payload));
  },

  PRODUCTS_PRODUCT_NEW_RESET: (state) => {
    const key = rs.generate({ length: 6 });
    return state.set('newProduct', initialState.get('newProduct').setIn(['variation', 'default'], key).setIn(['variation', 'options', 0, 'key'], key));
  },

  PRODUCTS_PRODUCT_NEW_FIELD_UPDATE: (state, action) => {
    const { field, value } = action.payload;
    return state.setIn(['newProduct', field], value);
  },
  PRODUCTS_PRODUCT_NEW_SET: (state, action) => {
    return state.set('newProduct', Immutable.fromJS(action.payload));
  },
  PRODUCTS_PRODUCT_NEW_VARIATION_FIELD_UPDATE: (state, action) => {
    const { field, value } = action.payload;
    return state.setIn(['newProduct', 'variation', field], value);
  },
  PRODUCTS_PRODUCT_NEW_VARIATION_OPTION_FIELD_UPDATE: (state, action) => {
    const { index, field, value } = action.payload;
    return state.setIn(['newProduct', 'variation', 'options', index, field], value);
  },
  PRODUCTS_PRODUCT_NEW_VARIATION_OPTION_ADD: (state) => {
    const key = rs.generate({ length: 6 });
    return state.updateIn(['newProduct', 'variation', 'options'], options => options.push(emptyOption.set('key', key)));
  },

  PRODUCTS_PRODUCT_FIELD_UPDATE: (state, action) => {
    const { field, value } = action.payload;
    return state.setIn(['product', field], value);
  },
  PRODUCTS_PRODUCT_SET: (state, action) => {
    return state.set('product', Immutable.fromJS(action.payload));
  },
  PRODUCTS_PRODUCT_VARIATION_FIELD_UPDATE: (state, action) => {
    const { field, value } = action.payload;
    return state.setIn(['product', 'variation', field], value);
  },
  PRODUCTS_PRODUCT_VARIATION_OPTION_FIELD_UPDATE: (state, action) => {
    const { index, field, value } = action.payload;
    return state.setIn(['product', 'variation', 'options', index, field], value);
  },
  PRODUCTS_PRODUCT_VARIATION_OPTION_ADD: (state) => {
    const key = rs.generate({ length: 6 });
    const option = emptyOption.set('key', key);
    return state.updateIn(['product', 'variation', 'options'], options => options.push(option).set('key', key));
  },

  PRODUCTS_PRODUCT_VARIATION_OPTION_REMOVE: (state, action) => {
    const { objectName, index } = action.payload;
    return state.removeIn([objectName, 'variation', 'options', index]);
  },

  PRODUCTS_PRODUCT_DROP_IMAGE_ADD: (state, action) => {
    const { key, file } = action.payload;
    return state.updateIn(['imageUpload', 'droppedImages'], (droppedImages) => {
      return droppedImages.push(Immutable.fromJS({ key, file }));
    }).setIn(['imageUpload', 'uploadProgress'], 0);
  },
  PRODUCTS_PRODUCT_DROP_IMAGE_REMOVE: (state, action) => {
    const index = state.getIn(['imageUpload', 'droppedImages']).findIndex((item) => (item.get('key') === action.payload));
    return state.deleteIn(['imageUpload', 'droppedImages', index]);
  },
  PRODUCTS_PRODUCT_UPLOAD_IMAGE_PROGRESS_SET: (state, action) => {
    return state.setIn(['imageUpload', 'uploadProgress'], action.payload);
  }

}, initialState);