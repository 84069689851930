import _ from 'lodash';

import {
  commitMutation,
  graphql,
} from 'react-relay';

import { ConnectionHandler } from 'relay-runtime';

import environment from '../Environment';

const mutation = graphql`
  mutation AddProductInStoreMutation($categoryId: ID, $productIds: [ID]) {
    addProductInStoreMutation(categoryId: $categoryId, productIds: $productIds) {
      ok
      error
      categoryStore {
        id
        products {
          id
          name
        }
      }
    }
  }
`;

export default ({ categoryId, productIds }, onCompleted) => {
  const variables = {
    categoryId,
    productIds
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: (proxyStore) => {
      },
      optimisticUpdater: proxyStore => {
      },
      onCompleted: (response) => {
        onCompleted();
      },
      onError: err => console.error(err),
    }
  );
};
