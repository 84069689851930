import React from 'react';
import PropTypes from 'prop-types';

import { is, isNil } from 'ramda';

const TextInput = ({
  className,
  label,
  placeholder,
  rows,
  type,
  value,
  onChange
}) => {

  const $onChange = (e) => {
    if (is(Function, onChange)) onChange(e.target.value);
  };

  return (
    <div className={className}>
      {!isNil(label) && (
        <div className="uikit-input-label">
          {label}
        </div>
      )}
      {rows === 1 && (
        <input
          type={type}
          autoCorrect="off"
          value={value}
          placeholder={placeholder}
          onChange={$onChange} />
      )}
      {rows !== 1 && (
        <textarea
          rows={rows}
          value={value}
          placeholder={placeholder}
          onChange={$onChange}
        />
      )}
    </div>
  );
};

TextInput.defaultProps = {
  label: null,
  onChange: null,
  placeholder: '',
  rows: 1,
};

TextInput.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  rows: PropTypes.number.isRequired
};

export default TextInput;