
import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

import { is } from 'ramda';

const mutation = graphql`
  mutation UpdateOrderStateMutation($orderRef: String, $state: OrderStateEnum) {
    updateOrderStateMutation(orderRef: $orderRef, state: $state) {
      order {
        id
        date,
        orderRef
        status {
          ...OrderStatus_status
        }
        total {
          ...OrderTotal_total
        }
        items {
          ...OrderItem_item
        }
      } 
    }
  }
`;

export default (orderRef, state, done) => {
  const variables = {
    orderRef,
    state
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: proxyStore => {
      },
      optimisticUpdater: proxyStore => {
      },
      onCompleted: (response) => {
        if (is(Function, done)) done();
      },
      onError: err => console.error(err),
    }
  );
};
