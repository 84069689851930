import React from 'react';
import CartListPage from './CartListPage';

const Carts = () => {

  return (
    <div>
      <h1>Paniers</h1>
      <CartListPage />
    </div>
  );
};

export default Carts;