import Tokens from './lib/Tokens';

const {
  Environment,
  Network,
  RecordSource,
  Store,
} = require('relay-runtime');

const fetchQuery = (operation, variables, cacheConfig, uploadables) => {
  return Tokens.getAccessToken().then(access_token => {
    let body;
    let headers;
    
    if (uploadables) {
      // eslint-disable-next-line no-undef
      body = new FormData();
      body.append('query', operation.text);
      body.append('variables', JSON.stringify(variables));
      Object.keys(uploadables).forEach((filename) => {
        // eslint-disable-next-line no-prototype-builtins
        if (uploadables.hasOwnProperty(filename)) {
          body.append(filename, uploadables[filename]);
        }
      });
    } else {
      body = JSON.stringify({
        query: operation.text,
        variables,
      });
      headers = {
        Accept: '*/*',
        'Content-Type': 'application/json'
      };
    }
    return fetch('/graphql', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        ...headers,
        'Authorization': `Bearer ${access_token}`
      },
      body
    }).then(response => {
      return response.json();
    });
  });
};

const source = new RecordSource();
const store = new Store(source);
const network = Network.create(fetchQuery);

const environment = new Environment({
  network,
  store,
});

export default environment;