const R = require('ramda');
import { useRelayEnvironment } from 'react-relay';
import UpdateCategoriesMutation from '../_graphql/mutations/UpdateCategoriesMutation';
import S from 'string';

const getPathToCategory = (categories, path) => {
  path = path.split('/');
  path.forEach((value, index) => {
    if (index !== 0) {
      path = R.append('children', path);
    } else {
      path = [];
    }
    path = R.compose(
      R.append(R.findIndex(R.propEq('url', value), R.isEmpty(path) ? categories : R.path(path, categories)))
    )(path);
  });

  return path;
};

const prepareCategories = (categories, parentCategoryId = null, list = []) => {

  categories.forEach(category => {
    list = R.append({
      id: category.id,
      name: category.name,
      url: category.url,
      description: category.description,
      redirect: category.redirect,
      parentCategoryId
    }, list);
    if (!R.isNil(category.children)) {
      list = prepareCategories(category.children, category.id, list);
    }
  });
  return list;
};

const useCategories = ({ categories }) => {

  const environment = useRelayEnvironment();

  const onAddCategory = (path, { name, url }) => {
    const category = {
      name,
      url,
      children: []
    };

    if (R.isEmpty(path)) {
      categories = R.append(category, categories);
    } else {
      path = getPathToCategory(categories, path);
      categories = R.over(
        R.lensPath(
          R.dropLast(1, path)
        ),
        R.append(category),
        categories
      );
    }
    UpdateCategoriesMutation(environment, {
      categories: prepareCategories(categories),
      operation: 'add'
    }, () => {
    });
  };

  const onUpdateCategory = (path, { name, url, description, redirect }) => {
    path = S(path).chompRight('/').s;
    path = getPathToCategory(categories, path);
    categories = R.over(
      R.lensPath(path),
      R.compose(
        R.assoc('name', name),
        R.assoc('url', url),
        R.assoc('description', description),
        R.assoc('redirect', redirect)
      ),
      categories
    );

    UpdateCategoriesMutation(environment, {
      categories: prepareCategories(categories),
      operation: 'update'
    }, () => {
    });
  };

  const onRemoveCategory = (path) => {
    path = getPathToCategory(categories, path);

    categories = R.over(
      R.lensPath(
        R.dropLast(1, path)
      ),
      R.remove(R.last(path), 1),
      categories
    );

    UpdateCategoriesMutation(environment, {
      categories: prepareCategories(categories),
      operation: 'remove'
    }, () => {
    });
  };

  return {
    onAddCategory,
    onRemoveCategory,
    onUpdateCategory
  };
};

export default useCategories;
