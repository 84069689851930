import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { is, isNil } from 'ramda';

const NumberInput = ({
  className,
  label,
  type,
  valueType,
  value,
  onChange
}) => {

  const $onChange = (e) => {
    e.preventDefault();
    if (!is(Function, onChange)) return;
    let v = e.target.value;
    if (valueType === 'int') onChange(parseInt(v));
    else if (valueType === 'float') onChange(parseFloat(v));
    else onChange(v);
  };

  return (
    <div className={classNames('uikit-text-input', className)}>
      {!isNil(label) && (
        <div className="uikit-input-label">{label}</div>
      )}
      <input
        type={type}
        value={value}
        onChange={$onChange} />
    </div>
  );
};

NumberInput.defaultProps = {
  label: null,
  onChange: null,
  placeholder: '',
  step: 1,
  min: -10000,
  max: 10000
};

NumberInput.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  valueType: PropTypes.oneOf(['int', 'float']),
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number
};

export default NumberInput;