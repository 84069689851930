import './RemoveProduct.less';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import RemoveProductMutation from '../../mutations/RemoveProductMutation';
import Button from '../uikit/buttons/Button';

class RemoveProduct extends Component {
  constructor () {
    super();
    this.state = {
      code: '',
      disabled: true,
      remove: false
    };
  }
  removeProduct () {
    RemoveProductMutation(this.props.productId, () => {
      this.setState({ removed: true });
    });
  }

  setCode (code) {
    this.setState({ code, disabled: code !== 'SUPPRIMER' });
  }

  render () {
    const { code, removed, disabled } = this.state;
    return (
      <div className="remove-product">
        { removed && < Redirect to="/products"/>}
        <input
          placeholder="tapez SUPPRIMER pour supprimer !" 
          value={code} 
          onChange={e => this.setCode(e.target.value)}
        />
        &nbsp;
        <Button style={{ marginTop: '16px' }} disabled={disabled} onClick={() => this.removeProduct()}>OK</Button>
      </div>
    ); 
  }
}

export default RemoveProduct;