
import './css/tailwind.css';
import './less/admin.less';
import 'react-select/dist/react-select.css';
import 'font-awesome/css/font-awesome.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { RelayEnvironmentProvider } from 'react-relay';
import GQLEnvironment from './_graphql/Environment';

import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import Tokens from './lib/Tokens';
import reducers from './reducers';
import Routes from './Routes';

Tokens.setKey(process.env.API_USER_ID, process.env.API_KEY);

const store = createStore(
  reducers,
  applyMiddleware(thunkMiddleware)
);

const getDocumentRoot = () => {
  var element = document.createElement('div');
  document.body.appendChild(element);
  return element;
};

(async () => {
  const environment = await GQLEnvironment();
  ReactDOM.render(
    <RelayEnvironmentProvider environment={environment}>
      <Routes store={store} />
    </RelayEnvironmentProvider>,
    getDocumentRoot()
  );
})();
