import React from 'react';
import RemoveElement from './RemoveElement';

const Title = ({ value, onChange, onRemove }) => (
  <div>
    title
    <RemoveElement onRemove={() => onRemove()}/>
  </div>
);

export default Title;