const R = require('ramda');
import React from 'react';
import useCategories from '../../hooks/useCategories';

import Category from './Category';

import CategoryHeader from './CategoryHeader';

import AddCategory from './AddCategory';

import {
  Collapse
} from 'antd';

const Panel = Collapse.Panel;

const CategoryList = ({
  categories
}) => {
  const {
    onAddCategory,
    onUpdateCategory,
    onRemoveCategory
  } = useCategories({ categories });
  return (
    < div className="categories" >
      <Collapse>
        {
          categories.map((category) => (
            <Panel header={<CategoryHeader category={category} rootPath="" onRemoveCategory={onRemoveCategory} />} key={category.id}>
              <Category
                category={category}
                rootPath={''}
                onAddCategory={onAddCategory}
                onRemoveCategory={onRemoveCategory}
                onUpdateCategory={onUpdateCategory}
              />
            </Panel>
          ))
        }
      </Collapse>
      <AddCategory onAddCategory={onAddCategory} path={''} />
    </div >
  );
};

export default CategoryList;

/*
  addCategory (path, category) {
    let pos;
    let cursor;
    let categories = [ ...this.state.categories ];
    cursor = categories;
    path.split('/').forEach((url, index) => {
      pos = _.findIndex(cursor, c => c.url === url);
      if (index === path.split('/').length - 1 ) {
        if (!_.isUndefined(cursor[pos].children)) {
          cursor[pos].children.push({ ...category, id: Math.random() });
        }
      } else {
        cursor = cursor[pos].children;
      }
    });
    this.setState(update(this.state, { $set: { categories } }));
  }
*/

/*
  removeCategory (path) {
    let pos;
    let cursor;
    let categories = [ ...this.state.categories ];
    cursor = categories;
    path.split('/').forEach((url, index) => {
      pos = _.findIndex(cursor, c => c.url === url);
      if (index === path.split('/').length - 1 ) {
        this.idsToRemove.push(cursor[pos].id);        
        cursor.splice(pos, 1);
      } else {
        cursor = cursor[pos].children;
      }
    });
    this.setState(update(this.state, { $set: { categories } }));
  }
*/

/*
  updateCategoryField (path, fieldName, value) {
    let pos;
    let cursor;
    let categories = [ ...this.state.categories ];
    cursor = categories;
    path.split('/').forEach((url, index) => {
      pos = _.findIndex(cursor, c => c.url === url);
      if (index === path.split('/').length - 1 ) {
        cursor[pos][fieldName] = value;
      } else {
        cursor = cursor[pos].children;
      }
    });
    this.setState(update(this.state, { $set: { categories } }));
  }
*/

/*
  save () {
    this.setState(update(this.state, { $set: { saving: true } }));
    UpdateCategoriesMutation(this.state.categories, this.idsToRemove, () => {
      this.setState(update(this.state, { $set: { saving: false } }));      
    });
  }
*/

/*
  renderCategories () {
    const { categories } = this.state;

    if (_.isEmpty(categories)) {
      return (
        <div>
          pas de catégorie encore définie
        </div>
      );
    }

    return (
      
    );
  }

  render () {
    return (
      <div className="categories">
        {this.renderCategories()}
      </div>
    );
  }
}
*/

