const R = require('ramda');
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../../Environment';

import {
  Button,
  Table
} from 'antd';

const defaultColumns = [
  {
    title: 'Nom du produit',
    dataIndex: 'name',
    key: 'name'
  }
];

const SelectedProductListQuery = graphql`
  query SelectedProductListQuery(
    $categoryId: String
  ) {
      categoryStore(categoryId: $categoryId) {
        id
        products {
          id
          name
        }
      }
  } 
`;

const SelectedProductList = ({
  categoryId,
  onRemoveProducts
}) => {

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onClickRemoveBtn = () => {
    onRemoveProducts(selectedRowKeys);
    setSelectedRowKeys([]);
  };

  const onSelect = (rowKeys) => {
    setSelectedRowKeys(rowKeys);
  };

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [categoryId]);


  return (
    <QueryRenderer
      environment={environment}
      query={SelectedProductListQuery}
      variables={{
        categoryId
      }}
      render={({ error, props }) => {
        if (error) {
          return <div>{error.message}</div>;
        } else if (props) {
          const products = R.pathOr([], ['categoryStore', 'products'], props);
          return (
            <div>
              <Button
                disabled={R.isEmpty(selectedRowKeys)}
                onClick={onClickRemoveBtn}
              >
                {'Enlever de la catégorie la sélection'}
              </Button>
              <Table
                rowKey="id"
                rowSelection={{ onChange: onSelect, selectedRowKeys }}
                size="small"
                dataSource={products}
                pagination={false}
                columns={defaultColumns}
              />
            </div>
          );
        }
        return <div>Loading</div>;
      }}
    />
  );
};

SelectedProductList.propTypes = {
  categoryId: PropTypes.string,
  onRemoveProducts: PropTypes.func
};

export default SelectedProductList;
