import React from 'react';
import { Switch, Route } from 'react-router-dom';

import CouponListPage from './CouponListPage';
import NewCoupon from './NewCoupon';

const Coupons = ({
  match
}) => {
  return (
    < div >
      <h1>Gestion des coupons</h1>
      <Switch>
        <Route exact path={`${match.url}/new`} component={NewCoupon} />
        <Route path={`${match.url}`} component={CouponListPage} />
      </Switch>
    </div >
  );
};

export default Coupons;