
import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

const mutation = graphql`
  mutation RemoveProductMutation($productId: ID) {
    removeProductMutation(productId: $productId) {
      code
    }
  }
`;

export default (productId, done) => {
  const variables = {
    productId
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: proxyStore => {
      },
      optimisticUpdater: proxyStore => {
      },
      onCompleted: (response) => {
        done(response.removeProduct);
      },
      onError: err => console.error(err),
    }
  );
};
