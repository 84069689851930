import React from 'react';
import PropTypes from 'prop-types';
import Category from './Category';

import CategoryAll from '../../queries/CategoryAll'; 

const Categories = ({ selectedCategory, onSelect }) => (
  <CategoryAll
    container={Category}
    containerProps={{
      selectedCategory,
      onSelect
    }}
  />
);

Categories.propTypes = {
  selectedCategory: PropTypes.string,
  onSelect: PropTypes.func
};

export default Categories;