import './Stores.less';
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import EditStore from './EditStore';

const Stores = ({ match }) => (

  <Switch>
    <Route 
      path={`${match.url}/`} exact render={() => (
        <EditStore/>
      )}
    />
  </Switch>
);

export default Stores;