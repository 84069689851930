import _ from 'lodash';
import React, { Component } from 'react';
import update from 'react-addons-update';
import styled from 'styled-components';
import { Button } from '../../uikit/UIKit';

import Address from './Address';

const S_BillingAddress = styled.div`
  margin-bottom: 16px;
  a.billing-check-box {
    display: flex;
    align-items: center;
    margin-top: 8px;
    text-decoration: none;
    color: #444;
  }
`;

class BillingAddress extends Component {

  render () {
    const { address, billingSameAsShipping } = this.props;
    return (
      <S_BillingAddress>
        {
          !billingSameAsShipping &&
          <a className="billing-check-box" href="javascript:void(0)" onClick={() => this.setSameAsShipping(true)}>
            <i style={{ marginRight: 8 }} className="fa fa-square-o"/>L'adresse de facturation est identique à l'adresse de livraison
          </a>
        }
        {
          billingSameAsShipping && 
          <a className="billing-check-box" href="javascript:void(0)" onClick={() => this.setSameAsShipping(false)}>
            <i style={{ marginRight: 8 }} className="fa fa-check-square-o"/>L'adresse de facturation est identique à l'adresse de livraison
          </a>
        }
        {
          !billingSameAsShipping && 
          <div>
            <div className="">Adresse de facturation</div>
            <Address {...{ address, type: 'billing' }}/>
          </div>
        }
      </S_BillingAddress>
    );
  }
}

export default BillingAddress;