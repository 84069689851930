import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TextInput, NumberInput } from '../uikit/UIKit';
import S from 'string';
import Checkbox from 'rc-checkbox';

import {
  Button
} from 'antd';

import { includes, is } from 'ramda';

const VariationOptionCoreEdit = ({
  defaultOptionKey,
  isDefaultOption,
  option,
  onRemove,
  onSetDefault,
  onUpdate
}) => {

  const $onUpdate = (fieldname, value) => {
    if (is(Function, onUpdate)) return onUpdate(fieldname, value);
  };

  const $onUpdateField = (fieldname) => (value) => {
    $onUpdate(fieldname, value);
  };

  const $onUpdateCheckboxField = (fieldname) => (e) => {
    $onUpdate(fieldname, e.target.checked);
  };

  const $onSetDefault = (e) => {
    if (is(Function, onSetDefault)) return onSetDefault();
  };


  const $onRemove = (e) => {
    e.preventDefault();
    if (is(Function, onRemove)) onRemove();
  };

  return (
    <div className="flex items-center my-2">
      <div className="flex-1 flex items-center">
        <div className="w-5">
          <input
            type="radio"
            name="default-option"
            value={option.key}
            checked={isDefaultOption}
            onChange={$onSetDefault}
          />
        </div>
        <TextInput
          className="w-30 mr-2"
          placeholder="Nom"
          value={option.name}
          onChange={$onUpdateField('name')} />
        <>
          <span className="text-xs">prix :</span>
          <NumberInput
            className="w-20 ml-1 mr-2"
            placeholder="Prix"
            type="number"
            valueType="float"
            value={option.price}
            step={0.05}
            onChange={$onUpdateField('price')}
          />
        </>
        <>
          <span className="text-xs">port :</span>
          <NumberInput
            className="w-20 ml-1 mr-2"
            placeholder="Transport"
            valueType="float"
            type="number"
            value={option.shippingCost}
            step={0.05}
            onChange={$onUpdateField('shippingCost')}
          />
        </>
        <>
          <span className="text-xs">stock :</span>
          <NumberInput
            className="w-20 ml-1 mr-2"
            placeholder="Stock" value={option.stock}
            valueType="int"
            type="number"
            onChange={$onUpdateField('stock')}
          />
        </>
        <div className="flex items-center">
          <Checkbox
            checked={option.noShipment}
            onChange={$onUpdateCheckboxField('noShipment')}
          />
          <label className="text-xs">
            <span className="ml-1 mr-2 text-xs">pas de transport</span>
          </label>
        </div>
        <div className="flex items-center">
          <Checkbox
            checked={option.hasNoExtraShippingCost}
            onChange={$onUpdateCheckboxField('hasNoExtraShippingCost')}
          />
          <label>
            <span className="ml-1 mr-2 text-xs">no extra shipping cost</span>
          </label>
        </div>
      </div>
      <Button
        size="small"
        shape="circle"
        icon="close"
        onClick={$onRemove}
      />

    </div >
  );
};

VariationOptionCoreEdit.propTypes = {
  defaultOptionKey: PropTypes.string,
  option: PropTypes.shape({
    name: PropTypes.string,
    price: PropTypes.number,
    shippingCost: PropTypes.number,
    stock: PropTypes.number
  }),
  updateVariationField: PropTypes.func.isRequired,
  updateVariationOptionField: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired
};

export default VariationOptionCoreEdit;