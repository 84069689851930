import './EditStore.less';
const R = require('ramda');
import React, { useState } from 'react';
import Categories from './Categories';
import Products from './components/Products';
import SelectedProductList from './components/SelectedProductList';

import AddProductInStoreMutation from '../../mutations/AddProductInStoreMutation';
import RemoveProductFromStoreMutation from '../../mutations/RemoveProductFromStoreMutation';

const EditStore = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);

  const onAddProducts = (productIds) => {
    if (R.isNil(selectedCategory)) return null;

    AddProductInStoreMutation({ productIds, categoryId: selectedCategory }, () => {
      console.log('product added in store');
    });

  };

  const onRemoveProducts = (productIds) => {
    RemoveProductFromStoreMutation({ productIds, categoryId: selectedCategory }, () => {
      console.log('product removed from store');
    });
  };

  const onSelectCategory = (categoryId) => {
    setSelectedCategory(categoryId); 
  };

  return (
    <div className="edit-store">
      <Categories
        selectedCategory={selectedCategory}
        onSelect={onSelectCategory}
      />
      <Products
        onAddProducts={onAddProducts}
      />
      <SelectedProductList
        categoryId={selectedCategory }
        onRemoveProducts={onRemoveProducts}
      />
    </div>
  );
};

export default EditStore;