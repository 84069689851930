
import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

const mutation = graphql`
  mutation CreateCouponMutation($coupon: NewCouponInput) {
    createCouponMutation(coupon: $coupon) {
      ok
      error
      coupon {
        id
      }
    }
  }
`;

export default ({ title, description, amount, code, stock, startDate, endDate }, onCompleted) => {
  const variables = {
    coupon: {
      title,
      description,
      amount,
      code,
      stock,
      startDate,
      endDate
    }
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: (proxyStore) => { },
      optimisticUpdater: proxyStore => {
      },
      onCompleted: (response) => {
        const { coupon } = response.createCouponMutation;
        onCompleted(coupon);
      },
      onError: err => console.error(err),
    }
  );
};
