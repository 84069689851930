import _ from 'lodash';
import React, { Component } from 'react';
import update from 'react-addons-update';
import PropTypes from 'prop-types';
import IPropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from '../uikit/UIKit';
import ProductCoreEdit from './ProductCoreEdit';

import CreateProductMutation from '../../mutations/CreateProductMutation';

class NewProduct extends Component {
  constructor () {
    super();

    this.state = {
      saving: false,
      product: {
        name: '',
        description: '',
        shippingCost: 0,
        price: 0,
        stock: 0
      },
      newProductId: null
    };
  }

  createProduct () {
    this.setState(update(this.state, { saving: { $set: true } }));
    CreateProductMutation(this.state.product, (productId) => {
      this.setState(update(this.state, { newProductId: { $set: productId }, saving: { $set: false } }));
    });
  }
  
  updateField (f, v) {

    this.setState(update(this.state, { product: { [f]: { $set: v } } }));
  }

  render () {
    const { product, newProductId } = this.state;

    if (!_.isNull(newProductId)) {
      return (
        <div>
          <h2>Le produit a été crée</h2>
          <Button onClick={this.resetForm}>Créer un nouveau produit</Button>
          <br/>
          <Link to={`/products/edit/${newProductId}`}>Editer le produit</Link>
        </div>
      );
    }

    return (
      <form onSubmit={(e) => {e.preventDefault(); this.createProduct();}}>
        <ProductCoreEdit
          product={product}
          onUpdateField={(f, v) => this.updateField(f, v)}
        />
        <Button fetching={this.state.saving} size="large" type="submit" style={{ marginTop: 16 }}>Créer le produit</Button>
      </form>
    );
  }
}

NewProduct.propTypes = {
  product: IPropTypes.mapContains({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    stock: PropTypes.number,
    shippingCost: PropTypes.number
  }),
  updateField: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  create: PropTypes.func.isRequired
};

export default NewProduct;
