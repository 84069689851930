import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import update from 'react-addons-update';
import styled from 'styled-components';
import { graphql, createFragmentContainer } from 'react-relay';
import CountryList from './CountryList';
import { Button } from '../../uikit/UIKit';

const S_Address = styled.div`
  .address-top {
    display: flex;
    align-items: center;
    a {
      text-decoration: none;
      text-transfrom: uppercase;
      font-size: 11px;
      text-transform: uppercase;
      padding-bottom: 8;
      color: red;
    }
  }
`;

class Address extends Component {
  render () {
    let { address } = this.props;
    address = _.isNull(address) ? {} : address;
    
    const { firstname, lastname, companyName, phone, field1, field2, field3, city, postcode, country } = address;
    
    return (
      <S_Address>
        {firstname} {lastname}
        {companyName !== '' && <div>{companyName}</div>}
        {phone !== '' && <div>{phone}</div>}
        {field1 !== '' && <div>{field1}</div>}
        {field2 !== '' && <div>{field2}</div>}
        {field3 !== '' && <div>{field3}</div>}
        <div>{postcode} &nbsp;{city}</div>
        <div>{CountryList.getTitle(country, 'fr')}</div>
      </S_Address>
    );

  }
}

export default createFragmentContainer(
  Address, 
  {
    address: graphql`
      fragment Address_address on Address {
        firstname
        lastname
        companyName
        phone
        field1
        field2
        field3
        city
        postcode
        country
        extra
        active
      }
    `
  }
);

Address.propTypes = {
  address: PropTypes.shape({
    country: PropTypes.string
  }),
  onCountryChange: PropTypes.func
};
