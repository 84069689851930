/**
 * @generated SignedSource<<4738c015878d18cce9aef78ed7a34989>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "HomeConfigElementValue",
  "kind": "LinkedField",
  "name": "value",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HomeConfigPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "HomeConfigElement",
        "kind": "LinkedField",
        "name": "homeConfig",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "HomeConfigPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "HomeConfigElement",
        "kind": "LinkedField",
        "name": "homeConfig",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "401cb8ece45957ce23bb408faebe4e3f",
    "id": null,
    "metadata": {},
    "name": "HomeConfigPageQuery",
    "operationKind": "query",
    "text": "query HomeConfigPageQuery {\n  homeConfig {\n    type\n    value {\n      url\n      text\n    }\n    id\n  }\n}\n"
  }
};
})();

node.hash = "4f3a6f8194907627c6366ecedf88a972";

module.exports = node;
