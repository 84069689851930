
import React from 'react';
import PropTypes from 'prop-types';
import Format from '../../helpers/Format';
import OrderTotal from './OrderTotal';
import OrderItem from './OrderItem';
import OrderStatus from './OrderStatus';
import OrderUser from './OrderUser';
import OrderAddresses from './OrderAddresses';
import UpdateOrderStateMutation from '../../mutations/UpdateOrderStateMutation';

import { isEmpty } from 'ramda';

const Order = ({
  orderRef,
  date,
  items,
  total,
  status,
  addresses,
  message,
  user
}) => {

  const $onUpdateState = (state) => {
    UpdateOrderStateMutation(orderRef, state, () => {

    });
  };
  return (
    <div>
      <h3>Commande <b>{orderRef} </b>passée le {Format.DateToString(date)}</h3>
      <OrderUser {...{ user }} />
      <OrderAddresses {...{ addresses }} />
      <div className="order-section-title">Liste des produits</div>
      <div className="order-items">
        {
          items.map((item, index) => <OrderItem key={index} {...{ item }} />)
        }
      </div>
      <div className="order-section-title">Total</div>
      <OrderTotal total={total} style={{ marginTop: 0 }} />
      {
        !isEmpty(message) &&
        <div>
          <div className="order-section-title">Message</div>
          {message}
        </div>
      }
      <div className="order-section-title">Etat de la commande</div>
      <OrderStatus
        {...{ status }}
        onUpdateState={$onUpdateState} />
    </div>
  );
};

Order.propTypes = {
  orderRef: PropTypes.string,
  message: PropTypes.string,
  date: PropTypes.string,
  status: PropTypes.object,
  total: PropTypes.object
};

export default Order;