/**
 * @generated SignedSource<<27d9323783003f28be58e11010a6176c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Product",
    "kind": "LinkedField",
    "name": "product",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductImage",
        "kind": "LinkedField",
        "name": "pictures",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "filename",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditProductQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditProductQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "bc4e219be0e4306b35f15ef0b5f4508e",
    "id": null,
    "metadata": {},
    "name": "EditProductQuery",
    "operationKind": "query",
    "text": "query EditProductQuery(\n  $input: ProductIdInput\n) {\n  product(input: $input) {\n    id\n    pictures {\n      id\n      filename\n    }\n  }\n}\n"
  }
};
})();

node.hash = "8f9d10bcdebbc84bbe21c1ec3deb0b51";

module.exports = node;
