import React from 'react';
import RemoveElement from './RemoveElement';

const OneImage = ({ value, onChange, onRemove }) => (
  <div>
    <input value={value[0].text} type="text"/>
    <RemoveElement onRemove={() => onRemove()}/>
  </div>
);

export default OneImage;
