const R = require('ramda');
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../Environment';

import CategoryList from './CategoryList';

import {
  Divider
} from 'antd';

const CategoryListPageQuery = graphql`
  query CategoryListPageQuery {
      categoryGroup {
        id
        value {
          id
          name
          description
          redirect
          url 
          children {
            id
            name
            description
            redirect
            url
            children {
              id
              name
              description
              redirect
              url
            }
          }
        }
      }
  } 
`;


const CategoryListPage = () => {
  const buildCategories = (categories) => {
    return categories.map(({ id, name, url, description, redirect, children }) => ({
      id,
      name,
      url,
      description,
      redirect,
      children: !R.isNil(children) ? buildCategories(children) : undefined
    }));
  };

  return (
    <QueryRenderer
      environment={environment}
      query={CategoryListPageQuery}
      render={({ error, props }) => {
        if (error) {
          return <div>{error.message}</div>;
        } else if (props) {
          const categories = R.pathOr([], ['categoryGroup', 'value'], props);
          return (
            <div className="categories">
              <Divider />
              <CategoryList categories={buildCategories(categories)} />
            </div>
          );
        }
        return <div>Loading</div>;
      }}
    />
  );

};

CategoryListPage.propTypes = {
  fetchAll: PropTypes.func.isRequired
};

export default CategoryListPage;
