import React from 'react';
import { Switch, Route } from 'react-router-dom';

import OrderList from './components/OrderList';
import OrderPage from '../order/OrderPage';

const Orders = ({ match }) => (
  <Switch>
    <Route path={`${match.url}`} exact render={() => <OrderList count={25}/>}/>
    <Route
      path={`${match.url}/:orderRef`} exact render={({ match }) => {
        return (<OrderPage orderRef={match.params.orderRef}/>);
      }}/>
  </Switch>
);

export default Orders;