/**
 * @generated SignedSource<<5e2a7d453f8c4b8d24ba95b6d809a892>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "productId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "productId",
        "variableName": "productId"
      }
    ],
    "concreteType": "RemoveProductMutationResponse",
    "kind": "LinkedField",
    "name": "removeProductMutation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveProductMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveProductMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7a1b954512bf2343bb0c43f4709b695b",
    "id": null,
    "metadata": {},
    "name": "RemoveProductMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveProductMutation(\n  $productId: ID\n) {\n  removeProductMutation(productId: $productId) {\n    code\n  }\n}\n"
  }
};
})();

node.hash = "b641b8b1400d326ab68f10250349cba6";

module.exports = node;
