import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Format from '../../helpers/Format';
import { graphql, createFragmentContainer } from 'react-relay';

const S_Total = styled.div`
  //margin: 32px 0px 24px 0px;
  width: 456px;
  .cart-line {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
  }
  .cart-extra-infos {
    margin-top: 8px;
    font-size: 10px;
    color: #333;
  }
`;

const OrderTotal = ({ total }) => {

  const renderValue = (title, value) => (
    <div className="cart-row">
      <div className="cart-cell-1">
        {title}
      </div>
      <div className="cart-cell-2">
        {Format.PriceToString(value)}
      </div>
      <div className="cart-cell-3"/>
    </div>
  );

  return (
    <S_Total>
      {renderValue('Produit(s)', total.productsWithTax)}
      {renderValue('Livraison', total.shippingWithTax)}
      {renderValue('Total', total.totalWithTax)}
      <div className="cart-extra-infos">
        (total ht: {Format.PriceToString(total.totalNoTax)}, tva: {Format.PriceToString(total.tax)})
      </div>
    </S_Total>  
  );
};

OrderTotal.propTypes = {
  total: PropTypes.shape({
    country: PropTypes.string,
    productsWithTax: PropTypes.number,
    productsNoTax: PropTypes.number,
    shippingNoTax: PropTypes.number,
    shippingWithTax: PropTypes.number,
    extraShippingWithTax: PropTypes.number,
    extraShippingNoTax: PropTypes.number,
    tax: PropTypes.number,
    totalWithTax: PropTypes.number,
    totalNoTax: PropTypes.number,
  })
};

export default createFragmentContainer(
  OrderTotal, 
  {
    total: graphql`
      fragment OrderTotal_total on CartTotal {
        country
        productsWithTax
        productsNoTax
        shippingNoTax
        shippingWithTax
        extraShippingWithTax,
        extraShippingNoTax
        tax
        totalWithTax
        totalNoTax
        mustCheckShippingCost
      }
    `
  }
);