import './ProductPicture.less';
import React from 'react';
import PropTypes from 'prop-types';

import {
  Button
} from 'antd';

const ProductPicture = ({
  filename,
  onRemove
}) => (
  <div className="product-picture">
    <img src={`/image/${filename}/100-3-2`}/>
    <Button 
      className="product-picture-remove"
      shape="round" 
      icon="delete"
      sie="small"
      onClick={onRemove}
    />
  </div>
);

ProductPicture.propTypes = {
  _id: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default ProductPicture;