import Immutable from 'immutable';
import { handleActions } from 'redux-actions';
import rs from 'randomstring';

const initialState = Immutable.fromJS({
  fetching: false,
  categories: { 
    root: [] 
  },
  categoryIsExtended: {},
  selectedCategoryId: 'root'
});

export const categories = handleActions({
  CATEGORIES_FETCHING_SET: (state, action) => {
    return state.set('fetching', action.payload);
  },
  CATEGORIES_CATEGORY_EXTENDED_SET: (state, action) => {
    const { categoryId, extended } = action.payload;
    return state.setIn(['categoryIsExtended', categoryId], extended);
  },
  CATEGORIES_CATEGORY_CHILDREN_SET: (state, action) => {
    const { categoryId, categories } = action.payload;
    return state.setIn(['categories', categoryId], Immutable.fromJS(categories));
  },
  CATEGORIES_SELECTED_CATEGORY_ID_SET: (state, action) => {
    return state.set('selectedCategoryId', action.payload);
  },
  CATEGORIES_CATEGORY_FIELD_SET: (state, action) => {
    const { categoryId, field, value } = action.payload;
    return state.setIn(['categories', categoryId, field], value);
  },
  CATEGORIES_SELECTED_CATEGORY_SET: (state, action) => {
    const updateCategory = (categories, path) => {
      const index = categories.findIndex((category) => category.get('key') === path.get('0'));
      if (path.count() === 1) {
        return categories.set(index, action.payload);
      }
      return categories.updateIn([index, 'categories'], categories => updateCategory(categories, path.shift()));
    };
    return state.update('categories', categories => updateCategory(categories, Immutable.fromJS(state.get('selectedCategoryPath').split('.'))));
  }
}, initialState);