import React, { Component } from 'react';
import update from 'react-addons-update';
import FontAwesome from 'react-fontawesome';

import OneImage from './elements/OneImage';
import Title from './elements/Title';

class HomeConfig extends Component {
  constructor (props) {
    super(props);

    this.state = {
      config: props.config || []
    };
  }

  addElement (type) {
    if (type === 'title') {
      this.setState(update(this.state, { config: { $push: [{ key: Math.random(), type: 'title', value: [{ text: 'empty' }] }] } }));
    } else if (type === 'oneImage') {
      this.setState(update(this.state, { config: { $push: [{ key: Math.random(), type: 'oneImage', value: [{ text: 'empty' }] }] } }));
    }
  }

  removeElement (index) {
    this.setState(update(this.state, { config: { $splice: [[index, 1]] } }));

  }

  updateConfig (index, config) {
    this.setState(update(this.state, { config: { $splice: [[index, 0, config]] } }));
  }


  renderElement (index, element) {
    if (element.type === 'oneImage') {
      return <OneImage key={element.key} index={index} value={element.value} onRemove={() => this.removeElement(index)}/>;
    } else if (element.type === 'title') {
      return <Title key={element.key} index={index} value={element.value} onRemove={() => this.removeElement(index)}/>;
    }
  }

  render () {
    return (
      <div>
        {'Configuration de la page d\'accueil'}
        {
          this.state.config.map((element, index) => (
            this.renderElement(index, element)
          ))
        }
        <div>
          <a href="javascript:void(0);" onClick={() => this.addElement('oneImage')}><FontAwesome name="plus"/>&nbsp;oneImage</a>
          <a href="javascript:void(0);" onClick={() => this.addElement('title')}><FontAwesome name="plus"/>&nbsp;title</a>
        </div>
      </div>
    );
  }
}

export default HomeConfig;
