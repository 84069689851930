import React from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../Environment';

import EditProduct from './EditProduct';

const EditProductPageQuery = graphql`
  query EditProductPageQuery($input: ProductIdInput) {
      product(input: $input) {
        id
        name
        slug
        redirect
        description
        price
        stock
        noShipment
        shippingCost
        url
        openForSale
        pictures {
          id
          filename
        }
        variation {
          name
          default
          options {
            key
            name
            price
            noShipment
            shippingCost
            stock
            hasNoExtraShippingCost
          }
        }
      }
  } 
`;

const EditProductPage = ({
  match,
}) => {

  return (
    <div>
      <QueryRenderer
        environment={environment}
        query={EditProductPageQuery}
        variables={{
          input: {
            id: match.params.productId
          }
        }}
        render={({ error, props }) => {
          if (error) {
            return <div>{error.message}</div>;
          } else if (props) {
            return (
              <EditProduct product={props.product} />
            );
          }
          return <div>Loading</div>;
        }}
      />
    </div>
  );
};


export default EditProductPage;
