import './Helper.less';
import React from 'react';

import {
  Spin
} from 'antd';
export const LoadMore = ({ onClick }) => (
  <a 
    href="javascript:void(0)" 
    className="query-load-more"
    onClick={(e) => {e.preventDefault(); onClick();}}
  >
    +
  </a>
);

export const LoadingMore = () => (
  <div className="query-loading-more">
    ...
  </div>
);

export const Loading = () => (
  <div className="loading">
    <Spin/>
  </div>
);