import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { graphql, createFragmentContainer } from 'react-relay';
import accounting from 'accounting';
import FontAwesome from 'react-fontawesome';

import styled from 'styled-components';

const S_OrderItem = styled.div`
.cart-item-img-and-title {
  display: flex;
  .cart-item-option {
    display: flex;
    align-items: center;
    color: #999;
    height: 20px;
    text-transform: uppercase;
    font-size: 12px;
  }
  &>div:last-of-type {
    padding-left: 8px;
  }
}

.cart-item-price {
  text-align: right;
 }

.cart-item-qty {
  display: flex;
  align-items: flex-start;
  &>div {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    text-align: center;
    span {
      font-size: 11px;
    }
  }
}
`;

class OrderItem extends Component {
  constructor () {
    super();
    this.state = {
      addOneIsDisabled: false,
      removeOneIsDisabled: false,
      removeItemIsDisabled: false
    };
  }

  renderOption () {
    const { optionKey, option } = this.props.item;

    if (optionKey === '') return null;
    return (
      <div className="cart-item-option">
        {`${option.name} - ${option.value.name}`}
      </div>
    );

  }
  render () {
    const { id, name, qty, price, option } = this.props.item;
    
    return (
      <S_OrderItem className="cart-row">
        <div className="cart-cell-1 cart-item-img-and-title">
          <div>
            <img src={`/product/${id}/image/100-3-2`} width={40}/>
          </div>
          <div>
            <Link to={`/product/${id}`}>{name}</Link>
            {this.renderOption()}
          </div>
        </div>
        <div className="cart-row-cell2 cart-item-price">
          {accounting.formatMoney(price, '', 2, ' ', ',')}&nbsp;€
        </div>
        <div className="cart-cell-3 cart-item-qty">
          <div>
            <span>qté&nbsp;:&nbsp;</span>{qty}
          </div>
        </div>
      </S_OrderItem>
    );
  }
}

OrderItem.defaultProps = {
  readOnly: false,
  delta: 0
};

OrderItem.propTypes = {
  item: PropTypes.shape({
    qty: PropTypes.number.isRequired,
    optionKey: PropTypes.string.isRequired,
    product: PropTypes.shape({
      option: PropTypes.shape({
        title: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
      })
    })
  }) 
};

export default createFragmentContainer(
  OrderItem, 
  {
    item: graphql`
      fragment OrderItem_item on OrderItem {
        id
        name
        qty
        optionKey
        price
        shippingCost
        option {
          name
          value {
            name
          }
        }
      }
    `
  }
);
