import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../Environment';

import HomeConfig from './HomeConfig';

const HomeConfigPageQuery = graphql`
query HomeConfigPageQuery {
    homeConfig {
      type
      value {
        url
        text
      }
    }
} 
`;

class HomeConfigPage extends Component {
  render () {
    return (
      <QueryRenderer
        environment={environment}
        query={HomeConfigPageQuery}
        render={({ error, props }) => {
          if (error) {
            return <div>{error.message}</div>;
          } else if (props) {
            return (
              <div className="query-product-list-page">
                <HomeConfig
                  config={props.HomeConfig}
                />
              </div>
            );
          }
          return <div>Loading</div>;
        }}
      />
    );
  }
}

export default HomeConfigPage;
