import React from 'react';
import update from 'react-addons-update';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc';

import {
  compose,
  withHandlers
} from 'recompose';

import ProductPicture from './ProductPicture';

const SortableItem = SortableElement(({ index, picture, onRemove }) => (
  <ProductPicture
    id={picture.id}
    index={index}
    filename={picture.filename}
    onRemove={onRemove}
  />
));

const SortableList = SortableContainer(({ pictures, removePicture }) => {
  return (
    <div className="flex w-full my-2">
      {pictures.map((picture, index) => (
        <SortableItem
          key={picture.id}
          index={index}
          picture={picture}
          onRemove={() => removePicture(index)}
        />
      ))
      }
    </div>
  );
});


const enhance = compose(
  withHandlers({
    movePicture: ({ pictures, onUpdate }) => ({ oldIndex, newIndex }) => {
      onUpdate(arrayMove(pictures, oldIndex, newIndex));
    },
    removePicture: ({ pictures, onUpdate }) => (index) => {
      onUpdate(update(
        pictures,
        { $splice: [[index, 1]] },
      ));
    }
  })
);
const ProductPictures = enhance(
  ({
    pictures,
    movePicture,
    removePicture
  }) => (
    <SortableList
      pictures={pictures}
      onSortEnd={movePicture}
      removePicture={removePicture}
      axis="x"
    />
  )
);

ProductPictures.propTypes = {
  onUpdate: PropTypes.func
  //removeOne: PropTypes.func.isRequired
};

export default ProductPictures;