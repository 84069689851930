import { combineReducers } from 'redux';

import { categories } from './reducers/categories';
import { products } from './reducers/products';
import { stores } from './reducers/stores';

const reducers = combineReducers({
  categories,
  products,
  stores
});

export default reducers;
