import React, { Component } from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../Environment';

import Order from './Order';

const OrderPageQuery = graphql`
  query OrderPageQuery($input: OrderIdInput) {
      order(input: $input) {
        id
        date
        orderRef
        message
        user {
          ...OrderUser_user
        }
        addresses {
          ...OrderAddresses_addresses
        }
        status {
          ...OrderStatus_status
        }
        total {
          ...OrderTotal_total
        }
        items {
          ...OrderItem_item
        }

      }
  } 
`;

class OrderPage extends Component {

  render () {
    const { orderRef } = this.props;
    return (
      <QueryRenderer
        environment={environment}
        query={OrderPageQuery}
        variables={{
          input: {
            id: orderRef
          }
        }}
        render={({ error, props }) => {
          if (error) {
            return <div>{error.message}</div>;
          } else if (props) {
            return (
              <Order {...props.order}/>
            );
          }
          return <div>Loading</div>;
        }}
      />
    );
  }
}

export default OrderPage;
