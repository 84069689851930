import './Products.less';

import React from 'react';
import { Switch, Route } from 'react-router-dom';

import All from './All';
import NewProduct from '../product/NewProduct';
import EditProductPage from '../product/EditProductPage';

const Products = ({ match }) => (

  <Switch>
    <Route 
      path={`${match.url}/`} exact render={() => (
        <All/>
      )}
    />
    <Route
      path={`${match.url}/new`} exact render={() => (
        <NewProduct/>
      )}
    />
    <Route
      path={`${match.url}/edit/:productId`} exact component={EditProductPage}/>
  </Switch>
);

export default Products;
