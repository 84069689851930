const R = require('ramda');
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../../Environment';

import { Loading } from '../../../queries/Helper';

import {
  Link,
  withRouter
} from 'react-router-dom';

import {
  Table
} from 'antd';

const defaultColumns = [
  {
    title: 'Nom du produit',
    dataIndex: 'name',
    key: 'name'
  }
];

const ProductListQuery = graphql`
query ProductListQuery(
  $pagination: PaginationInput
  $filter: ProductFilterInput
) {
    productsWithPagination(filter: $filter, pagination: $pagination) {
      products {
        id
        name
      }
      pagination {
        total
        page
        pages
        limit
      }
    }
} 
`;

const formatPagination = (pagination) => ({
  total: pagination.total,
  pageSize: pagination.limit,
  current: pagination.page
});

const ProductList = ({
  filter,
  showActions,
  count,
  onSelect,
  showPagination,
  selectedProductIds,
  history
}) => {

  let productSelection = !R.isNil(onSelect) ? { onChange: onSelect, selectedRowKeys: selectedProductIds } : undefined;
  const [pagination, setPagination] = useState({ page: 1, limit: count });

  const onChangePagination = ({ current }) => {
    setPagination((pagination) => R.assoc('page', current, pagination));
  };

  let columns = defaultColumns;

  if (showActions) {
    columns = R.append({
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <Link to={`/products/edit/${record.id}`}>{'Editer'}</Link>
        </span>
      ),
    }, columns);
  }

  return (
    <QueryRenderer
      environment={environment}
      query={ProductListQuery}
      variables={{
        filter,
        pagination
      }}
      render={({ error, props }) => {
        if (error) {
          return <div>{error.message}</div>;
        } else if (props) {
          const products = R.pathOr([], ['productsWithPagination', 'products'], props);
          const pagination = R.pathOr(undefined, ['productsWithPagination', 'pagination'], props);
          return (
            <Table
              rowKey="id"
              rowSelection={productSelection}
              size="small"
              dataSource={products}
              pagination={showPagination ? formatPagination(pagination) : false}
              columns={columns}
              onChange={onChangePagination}
              onRow={(record) => {
                return {
                  onClick: () => {
                    history.push(`/products/edit/${record.id}`);
                  }
                };
              }}
            />
          );
        }
        return <Loading/>;
      }}
    />
  );
};

ProductList.defaultProps = {
  showActions: false,
  showPagination: true
};

ProductList.propTypes = {
  showPagination: PropTypes.bool,
  showActions: PropTypes.bool,
  filter: PropTypes.shape({
    name: PropTypes.string
  })
};

export default withRouter(ProductList);
