const R = require('ramda');
import React, { useState } from 'react';

import { 
  Input,
  Button
} from 'antd';

import ProductList from '../../products/components/ProductList';

const Products = ({
  onAddProducts
}) => {
  const [filter, setFilter] = useState('');
  const [selectedProductIds, setSelectedProductIds] = useState([]);

  const onFilterChange = (e) => {
    setSelectedProductIds([]);
    setFilter(e.target.value);
  };
  
  return (
    <div>
      <Input
        value = {filter}
        onChange={onFilterChange}
        placeholder="Chercher un produit"
      />
      <Button
        onClick={() => onAddProducts(selectedProductIds)}
        size="small"
        disabled={R.isEmpty(selectedProductIds)}
      >
        {'Ajouter dans la catégorie la sélection'}
      </Button>
      <ProductList
        filter={{ name: filter }} 
        count={25}
        showActions={false}
        onSelect={setSelectedProductIds}
        selectedProductIds={selectedProductIds}
        showPagination={false}
      />
    </div>
  );
};

export default Products;