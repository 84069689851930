import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

class SelectInput extends React.Component {
  constructor () {
    super();
    this.onChange = this.onChange.bind(this);
  }
 
  onChange (items) {
    const { onChange } = this.props;

    if (!_.isNull(onChange)) this.props.onChange(items);
  }

  showLabel () {
    const { label } = this.props;
    if (_.isNull(label)) return null;

    return (
      <div className="uikit-input-label">{label}</div>
    );
  }

  render () {
    var { name, value, valueKey, labelKey, multi, options, onBlur, optionComponent, optionRenderer, valueRenderer } = this.props;

    if (multi) {
      value = _.map(value, 'name');
    }
    return (
      <div className="uikit-select-input">
        {this.showLabel()}
        <Select
          labelKey={labelKey}
          valueKey={valueKey}
          multi={multi}
          name={name}
          options={options}
          value={value}
          onBlur={onBlur}
          onChange={this.onChange}
          optionComponent={optionComponent}
          optionRenderer={optionRenderer}
          valueRenderer={valueRenderer}
        />
      </div>
    );
  }
}

SelectInput.defaultProps = {
  label: null,
  labelKey: 'label',
  valueKey: 'value',
  onChange: null,
  value: null,
  name: null,
  multi: false,
  optionRenderer: null,
  optionComponent: undefined,
  onBlur: undefined,
  valueRenderer: null
};

SelectInput.propTypes = {
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  name: PropTypes.string,
  multi: PropTypes.bool,
  label: PropTypes.string,
  optionRenderer: PropTypes.func,
  valueRenderer: PropTypes.func
};

export default SelectInput;