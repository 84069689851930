const R = require('ramda');
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../../Environment';

import { Loading } from '../../../queries/Helper';

import accounting from 'accounting';

import {
  Link,
  withRouter
} from 'react-router-dom';

import {
  Row,
  Col,
  Table,
  Tag
} from 'antd';

import Moment from 'moment';

const tagColor = (k) => R.propOr('blue', k, {
  cancelled: 'cyan',
  new: 'green',
  on_its_way: 'purple'
});

const tagTranslation = (k) => R.propOr(k, k, {
  cancelled: 'annulée',
  new: 'nouveau',
  on_its_way: 'en chemin'
});

const defaultColumns = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: (v) => {
      return Moment(v).format('DD/MM/YYYY - HH:mm:ss');
    },
    width: 200
  },
  {
    title: 'Statut',
    dataIndex: 'status.state',
    key: 'status.state',
    width: 150,
    render: (v) => <Tag color={tagColor(v)}>{tagTranslation(v)}</Tag>
  },
  {
    title: 'Email',
    dataIndex: 'user.email',
    key: 'user.email'
  },

  {
    title: 'Montant',
    dataIndex: 'total.totalWithTax',
    key: 'total.totalWithTax',
    render: v => `${accounting.formatMoney(v, '', 2, ' ', ',')} €`
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <span>
        <Link to={`/orders/${record.orderRef}`}>{'Voir'}</Link>
      </span>
    ),
  }
];

const OrderListQuery = graphql`
query OrderListQuery(
  $pagination: PaginationInput
  $filter: OrderFilterInput
) {
    ordersWithPagination(filter: $filter, pagination: $pagination) {
      orders {
        id,
        orderRef
        date
        status {
          state
        }
        user {
          email
        }
        total {
          totalWithTax
        }
      }
      pagination {
        total
        page
        pages
        limit
      }
    }
} 
`;

const formatPagination = (pagination) => ({
  total: pagination.total,
  pageSize: pagination.limit,
  current: pagination.page
});

const OrderList = ({
  filter,
  count,
  history
}) => {

  const [pagination, setPagination] = useState({ page: 1, limit: count });

  const onChangePagination = ({ current }) => {
    setPagination((pagination) => R.assoc('page', current, pagination));
  };

  let columns = defaultColumns;

  /*
  if (showActions) {
    columns = R.append(, columns);
  }
  */

  return (
    <QueryRenderer
      environment={environment}
      query={OrderListQuery}
      variables={{
        filter,
        pagination
      }}
      render={({ error, props }) => {
        if (error) {
          return <div>{error.message}</div>;
        } else if (props) {
          const orders = R.pathOr([], ['ordersWithPagination', 'orders'], props);
          const pagination = R.pathOr(undefined, ['ordersWithPagination', 'pagination'], props);
          return (
            <Row>
              <Col span={20}>
                <Table
                  rowKey="id"
                  size="small"
                  dataSource={orders}
                  pagination={formatPagination(pagination)}
                  columns={columns}
                  onChange={onChangePagination}
                  onRow={(record) => {
                    return {
                      onClick: () => {
                        history.push(`/orders/${record.orderRef}`);
                      }
                    };
                  }}
                />
              </Col>
            </Row>
          );
        }
        return <Loading/>;
      }}
    />
  );
};

OrderList.defaultProps = {
  showActions: false,
  showPagination: true
};

OrderList.propTypes = {
  filter: PropTypes.shape({
    name: PropTypes.string
  })
};

export default withRouter(OrderList);
