import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql, createFragmentContainer } from 'react-relay';

const S_OrderUser = styled.div`
  margin: 32px 0px 24px 0px;
  width: 456px;
  .cart-line {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
  }
  .cart-extra-infos {
    margin-top: 8px;
    font-size: 10px;
    color: #333;
  }
`;

const OrderUser = ({ user }) => {
  const { email, firstname, lastname } = user;
  return (
    <S_OrderUser>
      {email} - {firstname}&nbsp;{lastname}
    </S_OrderUser>  
  );
};

OrderUser.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string
  })
};

export default createFragmentContainer(
  OrderUser, 
  {
    user: graphql`
      fragment OrderUser_user on OrderUser {
        email
        firstname
        lastname
      }
    `
  }
);