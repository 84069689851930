import React, { Component } from 'react';
import { Layout } from '../uikit/UIKit';

class Home extends Component {

  constructor () {
    super ();
  }

  render () {
    return (
      <div>Hello</div>
    );
  }
}

export default Home;
