import './EditProduct.less';
const R = require('ramda');
import React, { useState } from 'react';
import { graphql } from 'react-relay';
import { fetchQuery } from 'relay-runtime';
import environment from '../../Environment';

import {
  Button,
  Form
} from 'antd';

import ProductCoreEdit from './ProductCoreEdit';
import ProductImageDropzone from './ProductImageDropzone';
import ProductPictures from './ProductPictures';
import RemoveProduct from './RemoveProduct';
import Variation from '../variation/Variation';

import useEditProduct from './useEditProduct';

import { isEmpty, is, isNil, path } from 'ramda';

const EditProductQuery = graphql`
  query EditProductQuery($input: ProductIdInput) {
      product(input: $input) {
        id
        pictures {
          id
          filename
        }
      }
  }
`;

const EditProduct = ({ product }) => {

  const {
    editedProduct,
    saving,
    onUpdateField,
    onSave
  } = useEditProduct(product);

  const onReloadPictures = async () => {
    const queryResponse = await fetchQuery(
      environment,
      EditProductQuery,
      { input: { id: product.id } }
    ).toPromise();
    const pictures = path(['product', 'pictures'], queryResponse);
    onUpdateField('pictures', pictures);
  };

  const $onSave = (e) => {
    e.preventDefault();
    onSave()
  }
  const $onUpdateField = (fieldname) => (value) => {
    if (is(Function, onUpdateField)) return onUpdateField(fieldname, value)
  }

  return (
    <Form className="edit-product">
      <ProductCoreEdit
        product={editedProduct}
        onUpdateField={onUpdateField}
      />
      {
        (
          isNil(editedProduct.redirect) ||
          isEmpty(editedProduct.redirect)) && (
          <>
            <div>
              <h4>Gestion des options</h4>
              <Variation
                for="product"
                variation={editedProduct.variation}
                onUpdate={$onUpdateField('variation')}
              />
            </div>
            <div style={{ margin: '32px 0px' }}>
              <h4>Gestion des images</h4>
              <div className="pictures-block">
                <ProductPictures
                  pictures={editedProduct.pictures}
                  onUpdate={$onUpdateField('pictures')}
                />
                <ProductImageDropzone
                  productId={editedProduct.id}
                  onImageUploaded={onReloadPictures}
                />
              </div>
            </div>
          </>
        )
      }
      <RemoveProduct productId={editedProduct.id} />
      <div style={{ paddingLeft: '312px' }} className="edit-product-save">
        <Button
          loading={saving}
          type="primary"
          size="large"
          onClick={$onSave}
        >
          {'Enregistrer'}
        </Button>
      </div>
    </Form>
  );
};

export default EditProduct;

/*
const EditProduct = ({
  product,
  containerWidth
}) => {

  const {
    editedProduct,
    saving,
    updateField,
    save
  } = useEditProduct(product);

  const reloadPictures = async () => {
    const pictures = R.path(['product', 'pictures'], await fetchQuery(environment, EditProductQuery, { input: { id: product.id } }));
    updateField('pictures', pictures);
  };

  return (
    <React.Fragment>
      <Form className="edit-product" onSubmit={save}>
        <ProductCoreEdit
          product={editedProduct}
          onUpdateField={updateField}
        />
        {
          (R.isNil(editedProduct.redirect) || R.isEmpty(editedProduct.redirect)) && (
            <React.Fragment>
              <div>
                <h4>Gestion des options</h4>
                <Variation for="product" variation={editedProduct.variation} onUpdate={variation => updateField('variation', variation)} />
              </div>
              <div style={{ margin: '32px 0px' }}>
                <h4>Gestion des images</h4>
                <div className="pictures-block">
                  <ProductPictures
                    pictures={editedProduct.pictures}
                    onUpdate={pictures => updateField('pictures', pictures)}
                  />
                  <ProductImageDropzone
                    productId={editedProduct.id}
                    onImageUploaded={reloadPictures}
                  />
                </div>
              </div>
            </React.Fragment>
          )
        }
        <RemoveProduct productId={editedProduct.id} />
        <div style={{ width: containerWidth + 32 }} className="edit-product-save">
          <Button
            loading={saving}
            type="primary"
            htmlType="submit"
            size="large"
          >
            {'Enregistrer'}
          </Button>
        </div>
      </Form>
    </React.Fragment>
  );
};
*/