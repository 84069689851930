import React from 'react';
import { useForm } from 'react-hook-form';
import CreateCouponMutation from '../../mutations/CreateCouponMutation';
import { isNil, toUpper } from 'ramda';

const NewCoupon = () => {
  const [done, setDone] = React.useState(false);
  const { register, handleSubmit, setValue, watch } = useForm();

  const $onReset = () => {
    setDone(false);
  };
  const $onSubmit = ({
    title,
    code,
    description,
    amount,
    stock
  }) => {
    CreateCouponMutation({
      title,
      code,
      description,
      amount: parseInt(amount),
      stock: parseInt(stock)
    }, coupon => {
      setDone(true);
    });
  };

  const code = watch('code');

  React.useEffect(() => {
    if (isNil(code)) return;
    setValue('code', toUpper(code));
  }, [code]);

  if (done) {
    return (
      <div>
        Le coupon a été créé.
        <br />
        <button className="t-btn" onClick={$onReset}>Créer un nouveau coupon</button>
      </div>
    );
  }
  return (
    <div className="w-1/3">
      <h1>Nouveau coupon</h1>
      <form onSubmit={handleSubmit($onSubmit)}>
        <div className="flex items-center mb-2">
          <div className="w-1/5">Titre</div>
          <input className="w-4/5 col-span-3" placeholder="titre" {...register('title')} />
        </div>
        <div className="flex items-center mb-2">
          <div className="w-1/5">Code</div>
          <input className="w-4/5 t-input col-span-3" placeholder="code" {...register('code')} />
        </div>
        <div className="flex items-center mb-2">
          <div className="w-1/5">Montant</div>
          <input className="w-4/5 t-input col-span-3" type="number" placeholder="montant (EUR)" {...register('amount')} />
        </div>
        <div className="flex items-center mb-2">
          <div className="w-1/5">Quantité</div>
          <input className="w-4/5 t-input col-span-3" type="number" placeholder="quantité" {...register('stock')} />
        </div>
        <div className="flex items-center mb-2">
          <div className="w-1/5">Description</div>
          <input className="w-4/5 t-input col-span-3" type="description" placeholder="description" {...register('description')} />
        </div>
        <button className="mt-4 t-btn" type="submit">Créer</button>
      </form>
    </div>
  );
};

export default NewCoupon;