/**
 * @generated SignedSource<<9f827d895a10350802f3a1028c020469>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pagination"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      },
      {
        "kind": "Variable",
        "name": "pagination",
        "variableName": "pagination"
      }
    ],
    "concreteType": "CartListPagination",
    "kind": "LinkedField",
    "name": "cartListWithPagination",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Cart",
        "kind": "LinkedField",
        "name": "carts",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastUpdateDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sessionId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CartTotal",
            "kind": "LinkedField",
            "name": "total",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "productsWithTax",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "productsNoTax",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shippingNoTax",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shippingWithTax",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "extraShippingWithTax",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "extraShippingNoTax",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tax",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalWithTax",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalNoTax",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Pagination",
        "kind": "LinkedField",
        "name": "pagination",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "total",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "page",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pages",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "limit",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CartListPageQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CartListPageQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "5259bac8947c4a8dd1eba63232bc27aa",
    "id": null,
    "metadata": {},
    "name": "CartListPageQuery",
    "operationKind": "query",
    "text": "query CartListPageQuery(\n  $pagination: PaginationInput\n  $filter: CartFilterInput\n) {\n  cartListWithPagination(filter: $filter, pagination: $pagination) {\n    carts {\n      id\n      lastUpdateDate\n      sessionId\n      total {\n        productsWithTax\n        productsNoTax\n        shippingNoTax\n        shippingWithTax\n        extraShippingWithTax\n        extraShippingNoTax\n        tax\n        totalWithTax\n        totalNoTax\n      }\n    }\n    pagination {\n      total\n      page\n      pages\n      limit\n    }\n  }\n}\n"
  }
};
})();

node.hash = "c4fc67e5dde80312742d442abf698d33";

module.exports = node;
