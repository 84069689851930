import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

const mutation = graphql`
  mutation RemoveProductFromStoreMutation($categoryId: ID, $productIds: [ID]) {
    removeProductFromStoreMutation(categoryId: $categoryId, productIds: $productIds) {
      ok
      error
      categoryStore {
        id
        products {
          id
          name
        }
      }
    }
  }
`;

export default ({ categoryId, productIds }, onCompleted) => {
  const variables = {
    categoryId,
    productIds
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: (proxyStore) => {},
      optimisticUpdater: proxyStore => {
      },
      onCompleted: (response) => {
        onCompleted();
      },
      onError: err => console.error(err),
    }
  );
};
