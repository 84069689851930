
import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

const mutation = graphql`
  mutation UpdateProductMutation($product: ProductInput) {
    updateProductMutation(product: $product) {
      code
    }
  }
`;

export default (product, done) => {
  const variables = {
    product
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: () => {
      },
      optimisticUpdater: () => {
      },
      onCompleted: (response) => {
        done();
      },
      onError: err => console.error(err),
    }
  );
};
