import './Button.less';
import _ from 'lodash';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Button = ({ children, to, fetching, onClick, size, disabled, style }) => {
  const renderFetching = () => {
    if (fetching) return <i style={{ marginLeft: 8 }} className="fa fa-spinner fa-spin fa-1x fa-fw"/>; else return null;
  };
  return React.createElement(
    _.isNull(to) ? 'button' : Link,
    { 
      style,
      to,
      onClick,
      className: classNames(
        'uikit-button', { 
          'uikit-button-small': size === 'small',
          'uikit-button-large': size === 'large',
          'uikit-button-disabled': disabled
        }
      )
    },
    <div>
      {children}
      {renderFetching()}
    </div>
  );
};

Button.defaultProps = {
  fetching: false,
  type: null,
  to: null,
  white: false,
  size: 'normal',
  disabled: false,
  onClick: () => {}
};

Button.propTypes = {
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'normal']),
  fetching: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func,
  white: PropTypes.bool,
  to: PropTypes.string
};

export default Button;