
import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

const mutation = graphql`
  mutation CreateProductMutation($product: NewProductInput) {
    createProductMutation(product: $product) {
      ok
      error
      product {
        id
      }
    }
  }
`;

export default ({ name, description, price, stock, shippingCost }, onCompleted) => {
  const variables = {
    product: {
      name,
      description,
      price,
      stock,
      shippingCost
    }
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: (proxyStore) => {},
      optimisticUpdater: proxyStore => {
      },
      onCompleted: (response) => {
        const { product: { id } } = response.createProductMutation;
        onCompleted(id);
      },
      onError: err => console.error(err),
    }
  );
};
