import './Categories.less';
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import CategoryListPage from './CategoryListPage';

const Categories = ({ match }) => (

  <Switch>
    <Route 
      path={`${match.url}/`} exact render={() => {
        return <CategoryListPage/>;
      }}
    />
  </Switch>
);

export default Categories;
