const R = require('ramda');
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import AddCategory from './AddCategory';
import EditCategory from './EditCategory';
import CategoryHeader from './CategoryHeader';

import {
  Collapse, Divider
} from 'antd';

const Panel = Collapse.Panel;

const Category = ({
  category,
  onAddCategory,
  onRemoveCategory,
  onUpdateCategory,
  rootPath
}) => {
  const childCategoryRootPath = `${rootPath}${category.url}/`;

  return (
    <div>
      <EditCategory
        category={category}
        onUpdateCategory={onUpdateCategory}
        path={childCategoryRootPath}
      />
      <Collapse>
        <Divider />
        {
          !R.isNil(category.children) && !R.isEmpty(category.children) && R.map((childCategory) => {
            return (
              <Panel
                key={childCategory.id}
                header={
                  <CategoryHeader
                    category={childCategory}
                    rootPath={childCategoryRootPath}
                    onRemoveCategory={onRemoveCategory}
                  />
                }
              >
                <Category
                  key={childCategory.key}
                  rootPath={childCategoryRootPath}
                  category={childCategory}
                  onRemoveCategory={onRemoveCategory}
                  onAddCategory={onAddCategory}
                  onUpdateCategory={onUpdateCategory}
                />
              </Panel>
            );
          }, category.children)
        }
      </Collapse>
      <AddCategory
        path={childCategoryRootPath}
        onAddCategory={onAddCategory}
      />
    </div>
  );
};

Category.propTypes = {
  category: PropTypes.shape({
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }),
  onUpdateField: PropTypes.func,
  onAddCategory: PropTypes.func,
  onRemoveCategory: PropTypes.func,
  onUpdateCategoryField: PropTypes.func,
  editedCategoryId: PropTypes.string,
  onEditCategory: PropTypes.func,
  path: PropTypes.path
  /*
  remove: PropTypes.func.isRequired,
  updateField: PropTypes.func.isRequired,
  */
};


export default Category;