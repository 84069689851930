const R = require('ramda');
import React from 'react';
import PropTypes from 'react-proptypes';

import { Provider } from 'react-redux';
import {
  Route,
  BrowserRouter as Router,
  Redirect,
  Switch
} from 'react-router-dom';

import Layout from './containers/uikit/Layout';
import Home from './containers/home/Home';
import HomeConfigPage from './containers/homeConfig/HomeConfigPage';

import environment from './Environment';

import {
  graphql,
  QueryRenderer
} from 'react-relay';

import Account from './containers/account/Account';
import Categories from './containers/categories/Categories';
import Coupons from './containers/coupons';
import Products from './containers/products/Products';
import Stores from './containers/stores/Stores';
import Orders from './containers/orders/Orders';
import Carts from './containers/carts/Carts';

const RoutesQuery = graphql`
query RoutesQuery {
    user {
      id
      email
    }
} 
`;

let Routes = ({ store }) => (
  <QueryRenderer
    environment={environment}
    query={RoutesQuery}
    render={({ error, props }) => {
      if (!props) return null;
      const { user } = props;
      const userIsSignedIn = !R.isEmpty(user) && !R.isNil(user.email);
      return (
        <Provider store={store}>
          <Router>
            <Layout userIsSignedIn={userIsSignedIn}>
              <Switch>
                <Route path="/account" component={Account} />
                {!userIsSignedIn && <Redirect to="/account/signin" />}
                <Route exact path="/" component={Home} />
                <Route exact path="/categories" component={Categories} />
                <Route path="/coupons" component={Coupons} />
                <Route exact path="/home-config" component={HomeConfigPage} />
                <Route path="/products" component={Products} />
                <Route path="/carts" component={Carts} />
                <Route path="/stores" component={Stores} />
                <Route path="/orders" component={Orders} />
              </Switch>
            </Layout>
          </Router>
        </Provider>
      );
    }}
  />
);

Routes.propTypes = {
  store: PropTypes.object.isRequired
};

export default Routes;
