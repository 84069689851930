const R = require('ramda');
import {
  commitMutation,
  graphql,
} from 'react-relay';

const mutation = graphql`
  mutation UpdateCategoriesMutation($categories: [CategoryInput], $operation: String) {
    updateCategoriesMutation(categories: $categories, operation: $operation) {
      ok
      error
      categoryGroup {
        id
        value {
          id
          name
          redirect
          url 
          description
          children {
            id
            name
            redirect
            url
            description
            children {
              id
              name
              redirect
              url
              description
            }
          }
        }
      }
    }
  }
`;



export default (environment, { categories, operation }, done) => {
  const variables = {
    categories,
    operation
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: () => {
      },
      optimisticUpdater: () => {
      },
      onCompleted: (response) => {
        done();
      },
      onError: err => console.error(err),
    }
  );
};
