const R = require('ramda');

import React from 'react';

import {
  compose,
  withHandlers,
  withProps,
  withState
} from 'recompose';

import {
  Button,
  Form,
  Input,
  Spin
} from 'antd';

const enhance = compose(
  withState (
    'removeStep',
    'setRemoveStep',
    ''
  ),
  withProps(
    ({ category, rootPath }) => ({
      path: `${rootPath}${category.url}`
    })
  ),
  withHandlers({
    onRemove: ({ category, form, onRemoveCategory, path, removeStep, setRemoveStep }) => (e) => {
      e.stopPropagation();
      if (removeStep === '') {
        return setRemoveStep('confirm');
      } else if (removeStep === 'confirm') {
        form.validateFields((err, { name }) => {
          if (!err) {
            if (name === category.name) {
              setRemoveStep('removing');
              onRemoveCategory(path);
            } else {
              setRemoveStep('');
            }
          }
        });
      }
    }
  })
);

const CategoryHeader = enhance(
  ({
    category,
    path,
    onRemove,
    removeStep,
    form    
  }) => {
 

    return (
      <div className="category-header">
        <div className="category-header-title">{`${category.name} (${path})`}</div>
        <Form layout="inline" style={{ marginLeft: '16px' }}>
          { 
            removeStep === 'confirm' &&
            <Form.Item>
              {form.getFieldDecorator('name', {
                rules: [{ required: true, message: 'Nom de la catégorie' }]
              })(
                <Input 
                  type="text"
                  size="small"
                  style={{ width: 300 }}
                  onClick={e => e.stopPropagation()} 
                  placeholder="pour effacer la catégorie, tapez son nom"
                />
              )}
            </Form.Item>
          }
          <Form.Item>
            {
              (removeStep === '' || removeStep === 'confirm') &&
              <Button onClick={onRemove} size="small">{'Effacer'}</Button>
            }
            {
              removeStep === 'removing' && <Spin size="small"/>
            }
          </Form.Item>
        </Form>
      </div>
    );
  }

);
export default Form.create({ name: 'CategoryHeader' })(CategoryHeader);