
const R = require('ramda');
import React from 'react';

import {
  Alert
} from 'antd';

const errors = {
  user_does_not_exist: 'L\'adresse email saisie n\'existe pas',
  password_ko: 'Le mot de passe est erroné',
  user_is_locked: 'Le compte de cet utilisateur est verrouillé'
};

const getErrorMessage = (error) => R.propOr(
  `Erreur inconnue [${error}]`,
  error,
  errors
);

const SignInError = ({
  error
}) => (
  <div style={{ height: 30, marginTop: 16 }}>
    {
      !R.isNil(error) && <Alert 
        type="error"
        message={getErrorMessage(error)}
      />
    }
  </div>
);

export default SignInError;