import React from 'react';
import PropTypes from 'prop-types';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../Environment';
import Moment from 'moment';
import accounting from 'accounting';

import {
  Row,
  Col,
  Table
} from 'antd';

import { assoc, has } from 'ramda';


const formatPagination = (pagination) => ({
  total: pagination.total,
  pageSize: pagination.limit,
  current: pagination.page
});



const CartListPageQuery = graphql`
  query CartListPageQuery(
    $pagination: PaginationInput
    $filter: CartFilterInput
  ) {
      cartListWithPagination(filter: $filter, pagination: $pagination) {
        carts {
          id
          lastUpdateDate
          sessionId
          total {
            productsWithTax
            productsNoTax
            shippingNoTax
            shippingWithTax
            extraShippingWithTax
            extraShippingNoTax
            tax
            totalWithTax
            totalNoTax
          }
        }
        pagination {
          total
          page
          pages
          limit
        }
      }
    } 
`;

const CartListPage = () => {
  const [pagination, setPagination] = React.useState({ page: 1, limit: 25 });

  const columns = React.useMemo(() => [
    {
      title: 'date',
      dataIndex: 'lastUpdateDate',
      key: 'lastUpdateDate',
      render: (v) => Moment(v).format('DD/MM/YYYY - HH:mm:ss'),
      width: 200
    }, {
      title: 'total',
      dataIndex: 'total.totalWithTax',
      key: 'total.totalWithTax',
      render: (v) => `${accounting.formatMoney(v, '', 2, ' ', ',')} €`
    }, {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
    }], []);

  const $onPaginationUpdate = (pagination) => {
    setPagination(assoc('page', pagination.current));
  };
  return (
    <QueryRenderer
      environment={environment}
      query={CartListPageQuery}
      variables={{
        pagination
      }}
      render={({ error, props }) => {
        if (error) {
          return <div>{error.message}</div>;
        } else if (has('cartListWithPagination', props)) {
          const { pagination, carts } = props.cartListWithPagination;
          return (
            <Row>
              <Col span={20}>
                <Table
                  rowKey="id"
                  size="small"
                  dataSource={carts}
                  pagination={formatPagination(pagination)}
                  columns={columns}
                  onChange={$onPaginationUpdate}
                />
              </Col>
            </Row>
          );
        }
        return <div>Loading</div>;
      }}
    />
  );

};

CartListPage.propTypes = {
  fetchAll: PropTypes.func.isRequired
};

export default CartListPage;
