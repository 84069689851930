import React from 'react';
import { Link } from 'react-router-dom';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../Environment';
import { pathOr } from 'ramda';
import {
  Table,
  Col,
} from 'antd';

const CouponListPageQuery = graphql`
  query CouponListPageQuery($filter: CouponFilterInput, $pagination: PaginationInput) {
      couponsWithPagination (filter: $filter, pagination: $pagination){
        coupons {
          id
          title
          code
          stock
          used
        }
        pagination {
          page
          pages
          limit
          total
        }  
      }
  } 
`;

const columns = [{
  title: 'Title',
  dataIndex: 'title',
  key: 'title',
}, {
  title: 'Code',
  dataIndex: 'code',
  key: 'code',
}, {
  title: 'Stock',
  dataIndex: 'stock',
  key: 'stock',
}, {
  title: 'Utilisé',
  dataIndex: 'used',
  key: 'used',
}
];

const formatPagination = (pagination) => ({
  total: pagination.total,
  pageSize: pagination.limit,
  current: pagination.page
});

const CouponList = () => {
  const [pagination, setPagination] = React.useState({ page: 1, limit: 10 });

  return (
    <QueryRenderer
      environment={environment}
      query={CouponListPageQuery}
      variables={{
        filter: {},
        pagination
      }}
      render={({ error, props }) => {
        const { coupons, pagination } = pathOr({ coupons: [], pagination: {} }, ['couponsWithPagination'], props);

        return (
          <Table
            className="mt-8"
            rowKey="id"
            size="small"
            dataSource={coupons}
            pagination={formatPagination(pagination)}
            columns={columns}
          />
        );
      }}
    />
  );
};

const CouponListPage = () => {

  return (
    <div>
      <Link className="t-btn" to="/coupons/new">Créer un coupon</Link>
      <CouponList />
    </div>
  );
};

export default CouponListPage;
