const R = require('ramda');

import React from 'react';

import {
  compose,
  defaultProps,
  withHandlers
} from 'recompose';

const enhance = compose(
  defaultProps({
    type: 'text'
  }),
  withHandlers({
    updateValue: ({ onChange }) => e => onChange(e.target.value)
  })
);

const Input = enhance(
  ({
    type,
    value,
    updateValue
  }) => (
    <input
      type={type}
      value={value} 
      onChange={updateValue}
    />
  )
);

export default Input;