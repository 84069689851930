const R = require('ramda');

import React from 'react';

import {
  Button,
  Form,
  Input,
  Row,
  Col
} from 'antd';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 24,
      offset: 4,
    },
  },
};

const EditCategory = ({
  category,
  form,
  onUpdateCategory,
  path
}) => {

  const $onSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        onUpdateCategory(path, R.pick(['name', 'url', 'redirect', 'description'], values));
      }
    });
  };

  return (
    <Form {...formItemLayout}>
      <Form.Item
        label="Nom"
      >
        {form.getFieldDecorator('name', {
          initialValue: category.name,
          rules: [{ required: true, message: 'Nom de la catégorie' }]
        })(
          <Input size="small" type="text" placeholder="nom de la catégorie" />
        )}
      </Form.Item>
      <Form.Item
        label="url"
      >
        {form.getFieldDecorator('url', {
          initialValue: category.url,
          rules: [{ required: true, message: 'Url de la catégorie' }]
        })(
          <Input size="small" type="text" placeholder="url de la catégorie" />
        )}
      </Form.Item>
      <Form.Item
        label="Redirect"
      >
        {form.getFieldDecorator('redirect', {
          initialValue: category.redirect,
          rules: [{ required: false, message: 'Redirection vers' }]
        })(
          <Input size="small" type="text" placeholder="url de la redirection" />
        )}
      </Form.Item>
      <Form.Item
        label="Description"
      >
        {form.getFieldDecorator('description', {
          initialValue: category.description,
          rules: [{ message: 'Description de la catégorie' }]
        })(
          <Input.TextArea
            type="text"
            size="small"
            placeholder="description de la catégorie"
            style={{ fontSize: 11 }}
          />
        )}
      </Form.Item>
      <Form.Item
        {...tailFormItemLayout}
      >
        <Button
          className="btn btn-default btn-sm"
          onClick={$onSubmit}
        >
          {'Enregistrer'}
        </Button>
      </Form.Item>
    </Form >
  );
};

export default Form.create({ name: 'EditCategory' })(EditCategory);
