const R = require('ramda');

import React from 'react';

import PropTypes from 'prop-types';

import {
  Input,
  InputNumber,
  Form,
  Button
} from 'antd';

const { TextArea } = Input;

import Checkbox from 'rc-checkbox';
//const MarkdownEditor = require('react-markdown-editor').MarkdownEditor;
import S from 'string';

import { curry, isNil } from 'ramda';

const useUpdaters = (onUpdateField) => {
  onUpdateField = curry(onUpdateField);
  const onUpdateName = (e) => onUpdateField('name', e.target.value);
  const onUpdateSlug = (e) => onUpdateField('slug', R.is(String, e) ? e : e.target.value);
  const onUpdateRedirect = (e) => onUpdateField('redirect', e.target.value);
  const onUpdateDescription = (e) => onUpdateField('description', e.target.value);
  const onUpdatePrice = (value) => onUpdateField('price', value);
  const onUpdateStock = (value) => onUpdateField('stock', value);
  const onUpdateNoShipment = (e) => onUpdateField('noShipment', e.target.checked);
  const onUpdateShippingCost = (value) => onUpdateField('shippingCost', value);
  const onUpdateHasNoExtraShippingCost = (e) => onUpdateField('hasNoExtraShippingCost', e.target.checked);
  const onUpdateOpenForSale = (e) => onUpdateField('openForSale', e.target.checked);

  return {
    onUpdateName,
    onUpdateSlug,
    onUpdateRedirect,
    onUpdateDescription,
    onUpdateNoShipment,
    onUpdatePrice,
    onUpdateStock,
    onUpdateShippingCost,
    onUpdateHasNoExtraShippingCost,
    onUpdateOpenForSale
  };
};

const ProductCoreEdit = ({
  product,
  onUpdateField
}) => {
  const {
    onUpdateName,
    onUpdateSlug,
    onUpdateRedirect,
    onUpdateDescription,
    onUpdateNoShipment,
    onUpdatePrice,
    onUpdateStock,
    onUpdateShippingCost,
    onUpdateHasNoExtraShippingCost,
    onUpdateOpenForSale
  } = useUpdaters(onUpdateField);

  const onSlugify = () => {
    onUpdateSlug(S(product.name).slugify().s);
  };
  console.log('----->', product.openForSale);
  return (
    <div>
      <div className="flex">
        <Checkbox checked={isNil(product.openForSale) || product.openForSale} onChange={onUpdateOpenForSale} />
        <label style={{ paddingLeft: '12px', margin: 0 }}>{'ouvert à la vente'}</label>
      </div>
      <Form.Item label="Nom du produit">
        <Input value={product.name} onChange={onUpdateName} />
      </Form.Item>
      <Form.Item label="Slug">
        <div style={{ display: 'flex' }}>
          <Input value={product.slug} onChange={onUpdateSlug} />
          <Button onClick={onSlugify}>{'slugify'}</Button>
        </div>
      </Form.Item>
      <Form.Item label="Redirection">
        <Input
          placeholder="/housings/lamaisonnette (pour afficher une page spécifique pour le produit)"
          value={product.redirect}
          onChange={onUpdateRedirect}
          allowClear={true}
        />
      </Form.Item>
      {(R.isNil(product.redirect) || R.isEmpty(product.redirect)) && (
        <React.Fragment>
          <Form.Item label="Prix">
            <InputNumber min={0} max={5000} value={product.price} step={0.05} onChange={onUpdatePrice} />
          </Form.Item>
          <Form.Item label="Stock">
            <InputNumber min={0} max={50} value={product.stock} onChange={onUpdateStock} />
          </Form.Item>
          <div className="form-group" style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Checkbox checked={product.noShipment} onChange={onUpdateNoShipment} />
            <label style={{ paddingLeft: '12px', margin: 0 }}>{'pas de transport'}</label>
          </div>
          <Form.Item label="Frais de port">
            <InputNumber min={0} max={100} step={0.05} value={product.shippingCost} onChange={onUpdateShippingCost} />
          </Form.Item>
          <div className="form-group" style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Checkbox checked={product.hasNoExtraShippingCost} onChange={onUpdateHasNoExtraShippingCost} />
            <label style={{ paddingLeft: '12px', margin: 0 }}>{'no extra shipping cost'}</label>
          </div>
          {/*<MarkdownEditor iconsSet="font-awesome" onContentChange={this.updateDescription}/>*/}
          <Form.Item label="Description">
            <TextArea rows={20} value={product.description} onChange={onUpdateDescription} />
          </Form.Item>
        </React.Fragment>
      )
      }
    </div>
  );
};

/*
ProductCoreEdit.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    stock: PropTypes.number,
    shippingCost: PropTypes.number,
    variation: PropTypes.shape({
      name: PropTypes.string,
      default: PropTypes.string,
      options: PropTypes.array(
        PropTypes.shape({
          name: PropTypes.string,
          price: PropTypes.number,
          shippingCost: PropTypes.number,
          stock: PropTypes.number
        })
      )
    })
  }),
  onUpdateField: PropTypes.func.isRequired,
};
*/

export default ProductCoreEdit;