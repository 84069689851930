import React, { Component } from 'react';
import PropTypes from 'react-proptypes';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../Environment';

const CategoryAllQuery = graphql`
  query CategoryAllQuery {
    categories {
      id
      name
      url 
      children {
        id
        name
        url
        children {
          id
          name
          url
        }
      }
    }
  } 
`;

const CategoryAll = ({ container, containerProps }) => (

  <QueryRenderer
    environment={environment}
    query={CategoryAllQuery}
    render={({ error, props }) => {
      if (error) {
        return <div>{error.message}</div>;
      } else if (props) {
        return (
          <div className="category-all">
            {props.categories.map(category => React.createElement(
              container,
              {
                key: category.id,
                ...containerProps,
                category
              }
            ))}
          </div>
        );
      }
      return <div>Loading</div>;
    }}
  />
);

export default CategoryAll;
