import React from 'react';

import {
  Menu,
  Button
} from 'antd';


import { Link } from 'react-router-dom';

const SubMenu = Menu.SubMenu;

import SignOutMutation from '../../mutations/SignOutMutation';

const MenuLeft = () => {

  const onSignOut = () => {
    SignOutMutation(() => { });
  };

  return (
    <div>
      <Menu
        mode="inline"
        defaultOpenKeys={['commands', 'navigation', 'products', 'store']}
        style={{ borderWidth: '0px' }}
      >
        <SubMenu key="commands" title={<span>Les commandes</span>}>
          <Menu.Item><Link to="/orders">{'Liste des commandes'}</Link></Menu.Item>
        </SubMenu>
        <SubMenu key="navigation" title={<span>La navigation</span>}>
          <Menu.Item><Link to="/categories">{'Gestions des catégories'}</Link></Menu.Item>
        </SubMenu>
        <SubMenu key="products" title={<span>Les produits</span>}>
          <Menu.Item><Link to="/products/new">Créer un produit</Link></Menu.Item>
          <Menu.Item><Link to="/products">Liste des produits</Link></Menu.Item>
        </SubMenu>
        <SubMenu key="store" title={<span>La boutique</span>}>
          <Menu.Item><Link to="/stores">Organisation des produits</Link></Menu.Item>
        </SubMenu>
        <SubMenu key="activity" title={<span>Activité</span>}>
          <Menu.Item><Link to="/carts">Les paniers</Link></Menu.Item>
        </SubMenu>
        <SubMenu key="misc" title={<span>Autres</span>}>
          <Menu.Item><Link to="/coupons">Gestion des coupons</Link></Menu.Item>
          <Menu.Item><Link to="/images">Gestion des ressources image</Link></Menu.Item>
          <Menu.Item><Link to="/home-config">{'Gestion de la page d\'accueil'}</Link></Menu.Item>
        </SubMenu>
      </Menu>
      <Button
        style={{ marginTop: 20 }}
        onClick={onSignOut}
      >
        {'Me déconnecter'}
      </Button>
    </div>
  );
};

export default MenuLeft;
