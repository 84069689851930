import './Variation.less';
import React from 'react';
import PropTypes from 'prop-types';
import IPropTypes from 'react-immutable-proptypes';
import VariationOptions from './VariationOptions';
import { TextInput } from '../uikit/UIKit';

import { assoc, is } from 'ramda';

const Variation = ({
  variation,
  onUpdate
}) => {

  const $onUpdate = (fieldname) => (value) => {
    console.log(fieldname, JSON.stringify(value));
    if (is(Function, onUpdate)) onUpdate(assoc(fieldname, value, variation));
  };

  return (
    <div className="variation">
      <TextInput
        placeholder="Nom de la variation"
        value={variation.name}
        onChange={$onUpdate('name')}
      />
      <VariationOptions
        options={variation.options}
        defaultOption={variation.default}
        onUpdate={$onUpdate('options')}
        onSetDefaultOption={$onUpdate('default')}
      />
    </div>
  );
};

Variation.propTypes = {
  onUpdateVariationField: PropTypes.func,
  onUpdateVariationOptionField: PropTypes.func
};


Variation.propTypes = {
  variation: PropTypes.shape({
    name: PropTypes.string,
    default: PropTypes.string,
    options: IPropTypes.listOf(
      IPropTypes.mapContains({
        key: PropTypes.string,
        name: PropTypes.string,
        price: PropTypes.number,
        noShipment: PropTypes.bool,
        shippingCost: PropTypes.number,
        stock: PropTypes.number
      })
    )
  }),
  updateField: PropTypes.func.isRequired
};

export default Variation;