import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Format from '../../helpers/Format';
import { graphql, createFragmentContainer } from 'react-relay';

import { 
  Col, 
  Row,
  Select,
  Timeline
} from 'antd';
import TimelineItem from 'antd/lib/timeline/TimelineItem';

const Option = Select.Option;

const OrderStatus = ({ status, onUpdateState }) => {
  const prepareStateOptions = (state) => {
    return OrderStatus.nextStates[state].map(v => ({
      value: v,
      label: OrderStatus.states[v].fr
    }));
  };
  return (
    <React.Fragment>
      <Row>
        <Col span="3">nouveau statut</Col>
        <Col span="8">
          <Select 
            style={{ width: '100%' }}
            size="small"
            onChange={v => onUpdateState(v)}
          >
            {
              prepareStateOptions(status.state).map(({ value, label }) => (
                <Option key={value} value={value}>{label}</Option>
              ))
            }
          </Select>
        </Col>
      </Row>
      <Row style={{ marginTop: 16 }}>
        <Timeline>
          {status.history.map(({ state, date }, index) => (
            <TimelineItem key={index}>
              {`${Format.DateToString(date)} - ${OrderStatus.states[state].fr}`}
            </TimelineItem>
          )).reverse()}
        </Timeline>
        
      </Row>
    </React.Fragment> );
};

OrderStatus.states = {
  new: {
    fr: 'Nouvelle',
    en: 'New'
  },
  cancelled: {
    fr: 'Annulée',
    en: 'Cancelled'
  },
  packing: {
    fr: 'En préparation',
    en: 'Packing'
  },
  on_its_way: {
    fr: 'En chemin',
    en: 'On its way'
  },
  closed: {
    fr: 'Fermée',
    en: 'Closed'
  }
};

OrderStatus.nextStates = {
  new: ['cancelled', 'packing'],
  cancelled: ['closed'],
  packing: ['new', 'on_its_way', 'cancelled'],
  on_its_way: ['packing', 'cancelled', 'closed'],
  closed: []
};

OrderStatus.propTypes = {
  status: PropTypes.shape({
    state: PropTypes.oneOf(['new', 'cancelled', 'on_its_way', 'packing', 'closed']),
    history: PropTypes.arrayOf(
      PropTypes.shape({
        state: PropTypes.oneOf(['new', 'cancelled', 'on_its_way', 'packing', 'closed']),
        date: PropTypes.string
      })
    )
  }),
  onUpdateState: PropTypes.func
};

export default createFragmentContainer(
  OrderStatus, 
  {
    status: graphql`
      fragment OrderStatus_status on OrderStatus {
        state
        history {
          state
          date
        }
      }
    `
  }
);