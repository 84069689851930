import React from 'react';
import UpdateProductMutation from '../../mutations/UpdateProductMutation';

import { assoc } from 'ramda';

const useEditProduct = (product) => {
  const [editedProduct, setEditedProduct] = React.useState(product);
  const [saving, setSaving] = React.useState(false);

  const onUpdateField = (key, value) => {
    setEditedProduct(assoc(key, value, editedProduct));
  };

  const onSave = () => {
    setSaving(true);
    UpdateProductMutation(editedProduct, () => {
      setTimeout(() => {
        setSaving(false);
      }, 200);
    });
  };

  return {
    editedProduct,
    saving,
    onUpdateField,
    onSave
  };
};

export default useEditProduct;