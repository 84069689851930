import Immutable from 'immutable';
import { handleActions } from 'redux-actions';

const initialState = Immutable.fromJS({
  selectedCategoryId: '',
  selectedProducts: [],
  selectedProductIds: []
});

export const stores = handleActions({
  STORES_SELECTED_CATEGORY_SET_ID: (state, action) => {
    return state.set('selectedCategoryId', action.payload);
  },
  STORES_SELECTED_PRODUCTS_SET: (state, action) => {
    return state
      .set('selectedProducts', Immutable.fromJS(action.payload));
  },
  STORES_PRODUCT_ADD: (state, action) => {
    return state
      .update('selectedProducts', products => products.push(action.payload))
      .update('selectedProductIds', ids => ids.push(action.payload.get('_id')));
  },
  STORES_PRODUCT_REMOVE: (state, action) => {
    return state
      .update('selectedProducts', products => products.remove(action.payload))
      .update('selectedProductIds', ids => ids.remove(action.payload));
  }
}, initialState);