const R = require('ramda');

import React from 'react';

import {
  Button,
  Form,
  Input,
} from 'antd';

const AddCategory = ({
  path,
  form,
  onAddCategory
}) => {
  const $onSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        onAddCategory(path, R.pick(['name', 'url'], values));
        form.setFieldsValue({ name: null, url: null });
      }
    });
  };

  return (
    <Form layout="inline" style={{ marginLeft: '16px' }}>
      <Form.Item>
        {form.getFieldDecorator('name', {
          rules: [{ required: true, message: 'Nouveau Nom de la catégorie' }]
        })(
          <Input size="small" type="text" placeholder="Nom de la nouvelle catégorie" />
        )}
      </Form.Item>
      <Form.Item>
        {form.getFieldDecorator('url', {
          rules: [{ required: true, message: 'Url de la nouvelle catégorie' }]
        })(
          <Input size="small" type="text" placeholder="url de la catégorie" />
        )}
      </Form.Item>
      <Button size="small" icon="save" style={{ marginTop: '8px' }} className="btn btn-default btn-sm" onClick={$onSubmit} />
    </Form>
  );
};

export default Form.create({ name: 'AddCategory' })(AddCategory);
