import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '../uikit/UIKit';

const Selected = styled.div`
  padding: 4 4;
  border: 1px #aaa solid;
`;

class Category extends Component {
  constructor () {
    super();
    this.state = {
      showChildren: false
    };
  }

  toggleShowChildren () {
    this.setState({ showChildren: !this.state.showChildren });
  }

  showChildren () {

    if (!this.state.showChildren) return null;

    return (
      <div className="store-category-children">
        {
          this.props.category.children.map(category => (
            <div className="store-category" key={category.id} style={{ paddingLeft: '8px' }}>
              <Category 
                category={category}
                selectedCategory={this.props.selectedCategory}
                onSelect={this.props.onSelect}
              />
            </div>
          ))
        }
      </div>
    );
  }

  render () {
    const { category } = this.props;
    return (
      <div className="store-category">
        <div className="store-category-product">
          <div>
            {
              this.state.showChildren &&
              <a href="javascript:void(0);" onClick={() => this.toggleShowChildren()}><i className="fa fa-minus-circle"/></a>
            }
            {
              !this.state.showChildren &&
            !_.isEmpty(this.props.category.children) &&
            <a href="javascript:void(0);" onClick={() => this.toggleShowChildren()}><i className="fa fa-plus-circle"/></a>
            }
            <span style={{ paddingLeft: '8px' }}>{category.name}</span>
          </div>
          {
            this.props.selectedCategory !== category.id &&
            <Button size="small" onClick={() => this.props.onSelect(category.id)}>Sélectionner</Button>
          }
          {
            this.props.selectedCategory === category.id &&
            <Button size="small" onClick={() => this.props.onSelect(null)}>Désélectionner</Button>
          }
        </div>
        {this.showChildren()}
      </div>
    );
  }
}

Category.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.string,
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired
  }),
  onSelect: PropTypes.func
};


export default Category;