import React from 'react';
import PropTypes from 'prop-types';

import {
  Upload
} from 'antd';

import { is } from 'ramda';
const Dragger = Upload.Dragger;

const ImageDropzone = ({
  productId,
  onImageUploaded
}) => {

  const onChange = (info) => {
    const status = info.file.status;
    if (status === 'done') {
      if (is(Function, onImageUploaded)) onImageUploaded();
    }
  };

  return (
    <Dragger
      name="image"
      multiple={false}
      action={`/upload/product/${productId}/image`}
      onChange={onChange}
    >
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
    </Dragger>
  );
};


ImageDropzone.propTypes = {
  productId: PropTypes.string.isRequired,
  uploadImage: PropTypes.func
};

export default ImageDropzone;
